import React, {FC, useEffect} from 'react';
import {useParams} from 'react-router-dom';
import {useAppDispatch, useAppSelector} from "../../../hooks/global";
import HeaderView from '../../common/Header/HeaderView'
import FooterView from '../../common/Footer/FooterView'
import TopBanner from '../../common/TopBanner/TopBanner'
import RootCategory from "./parts/RootCategory";
import Category from "./parts/Category";
import {fetchCategories, fetchPages} from "../../../store/library/librarySlice";
import Spinner from "../../common/Spinner/Spinner";
import './Library.scss'

const Library: FC = () => {

    const dispatch = useAppDispatch();
    const {categories, category, pages, isLoading} = useAppSelector(state => state.library);
    const {alias} = useParams();

    useEffect(() => {
        dispatch(fetchCategories(alias || 'root'));
        dispatch(fetchPages(alias || 'root'));
    }, []);

    useEffect(() => {
        window.scrollTo(0,0)
    },[])

    return (
        <div className="contentWrapper">
            <HeaderView/>
            <Spinner isLoading={isLoading}/>
            <div className="pageContainer">
                <TopBanner title={alias==='federal-and-state-resources' ? 'Federal & State Resources' : 'Library'}/>
                <div className="pageContainer__view">
                    {alias ? (
                        <Category categories={categories} pages={pages}/>
                    ) : (
                        <RootCategory items={categories}/>
                    )}
                </div>
            </div>
            <FooterView/>
        </div>
    );
};

export default Library;
