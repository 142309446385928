import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import api from '../../service/api';
import FileSaver from "file-saver";

export const fetchQuestions = createAsyncThunk('questions', async (params: any) => {
    const {packageType} = params;
    const response = await api.get(`customer/questionnaire/questions/${packageType}`);
    return response.data;
});

export const fetchAnswer = createAsyncThunk('question/answer', async (params: any) => {
    const response = await api.post(`customer/questionnaire/answer`, params);
    return response.data.resource;
});

export const fetchResetQuestionnaire = createAsyncThunk('question/reset', async () => {
    const response = await api.post(`customer/questionnaire/reset`);
    return response.data.resource;
});

export const fetchDocDownload = createAsyncThunk('question/doc-download', async (doc_type: string, thunkAPI) => {
    thunkAPI.dispatch(setDocName(`${doc_type}.pdf`));
    return api.get(`customer/questionnaire/doc-generate/${doc_type}`, {responseType: "blob"});
});

interface QuestionState {
    questions: Array<any>;
    order: any | null;
    last_answer: number | null;
    docName: string | null;
    isLoading: boolean;
}

const initialState: QuestionState = {
    questions: [],
    order: null,
    last_answer: null,
    docName: null,
    isLoading: false,
};

export const questionnaireSlice = createSlice({
    name: 'questionnaire',
    initialState,
    reducers: {
        setDocName: (state, action) => {
            state.docName = action.payload;
        },
        setCurrentAnswer: (state, action) => {
            const {idx, value} = action.payload;
            state.questions[idx].answer.answer_value = value;
        },
    },
    extraReducers: builder => {
        builder
            .addCase(fetchQuestions.pending, state => {
                state.isLoading = true;
            })
            .addCase(fetchQuestions.fulfilled, (state: any, action: any) => {
                state.questions = action.payload.resource.questions;
                state.order = action.payload.resource.order;
                state.last_answer = action.payload.resource.last_answer;
                state.isLoading = false;
            })
            .addCase(fetchQuestions.rejected, state => {
                state.isLoading = false;
            })
            .addCase(fetchAnswer.pending, state => {
                state.isLoading = true;
            })
            .addCase(fetchAnswer.fulfilled, (state: any, action: any) => {
                state.isLoading = false;
            })
            .addCase(fetchAnswer.rejected, state => {
                state.isLoading = false;
            })
            .addCase(fetchDocDownload.pending, state => {
                state.isLoading = true;
            })
            .addCase(fetchDocDownload.fulfilled, (state: any, action: any) => {
                state.isLoading = false;
                FileSaver.saveAs(action.payload, state.docName || 'doc.pdf');
            })
            .addCase(fetchDocDownload.rejected, state => {
                state.isLoading = false;
            })
            .addCase(fetchResetQuestionnaire.pending, state => {
                state.isLoading = true;
            })
            .addCase(fetchResetQuestionnaire.fulfilled, (state: any, action: any) => {
                state.isLoading = false;
            })
            .addCase(fetchResetQuestionnaire.rejected, state => {
                state.isLoading = false;
            })
            .addDefaultCase(() => {
            });
    },
});

export const {setDocName, setCurrentAnswer} = questionnaireSlice.actions;

export default questionnaireSlice.reducer;
