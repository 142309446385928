const getDomainUri = () => {
  switch (process.env.REACT_APP_ENV) {
    case 'local':
      return 'https://backend.dev.dfg.dnmiss.com';
    case 'dev':
      return 'https://backend.dev.dfg.dnmiss.com';
    case 'prod':
      return 'https://backend.dev.dfg.dnmiss.com';
    default:
      return 'https://backend.dev.dfg.dnmiss.com';
  }
};

export const DOMAIN_URI = getDomainUri();
export const API_URI = `${DOMAIN_URI}/api/`;