import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import api from '../../service/api';

export const fetchStates = createAsyncThunk('general/states', async (_, {rejectWithValue, dispatch}) => {
    const response = await api.get('general/states');
    dispatch(setStates(response.data.resource));
});

export const fetchPrices = createAsyncThunk('general/prices', async (params: any, {rejectWithValue, dispatch}) => {
    const response = await api.get('general/prices', {params});
    dispatch(setPrices(response.data.resource.prices));
    dispatch(setPublishableKey(response.data.resource.publishable_key));
    dispatch(setAffiliateId(response.data.resource.affiliate_id));
});

interface GlobalState {
    isLoading: boolean;
    publishable_key: string,
    affiliate_id: number | null,
    states: any[],
    prices: {
        non_reg_price: number,
        dot_fmcsa_price: number,
        non_reg_dot_fmcsa_price: number,
    },
}

const initialState: GlobalState = {
    isLoading: false,
    publishable_key: '',
    affiliate_id: null,
    states: [],
    prices: {
        non_reg_price: 775,
        dot_fmcsa_price: 375,
        non_reg_dot_fmcsa_price: 975,
    },
};

export const generalSlice = createSlice({
    name: 'general',
    initialState,
    reducers: {
        setStates: (state, action) => {
            state.states = action.payload;
        },
        setPrices: (state, action) => {
            state.prices = action.payload;
        },
        setPublishableKey: (state, action) => {
            state.publishable_key = action.payload;
        },
        setAffiliateId: (state, action) => {
            state.affiliate_id = action.payload;
        }
    },
});

export const {setStates, setPrices, setPublishableKey, setAffiliateId} = generalSlice.actions;
export default generalSlice.reducer;
