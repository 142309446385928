import React, {FC, useEffect, useState} from 'react';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import Link from '@mui/material/Link';
import Button from '@mui/material/Button';
import { fetchUser, fetchLogout } from '../../../store/auth/authSlice';
import { useAppDispatch, useAppSelector } from '../../../hooks/global';
import headerLogoImg from '../../../assets/images/header_logo.png';
import logout from '../../../assets/images/icons/logout.svg';
import './HeaderView.scss';

const HeaderView: FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split('/');
  const [menuOpen, setMenuOpen] = useState(false);
  const {user, role} = useAppSelector(state => state.auth);

  useEffect(() => {
    if (pathname != '/select-package' && pathname != '/thanks') {
      dispatch(fetchUser());
    }
  }, []);

  const handleLogout = () => {
    dispatch(fetchLogout()).then(resp => {
      navigate('/sign-in');
    });
  };

  return (
    <div className="headerContainer">
      <div className="headerView">
        <div className="headerLogo">
          <a href={'https://www.drugfreeguru.com'} target={'_blank'} rel={'noreferrer'}><img className="headerLogo__img" src={headerLogoImg} alt="Logo" /></a>
        </div>
        <div style={{height: '81px'}}></div>

        {(pathname != '/select-package') && (
            <ul className={`headerNavigation ${menuOpen ? 'state_open' : ''}`}>
              <li
                  className={`headerNavigation__item ${
                      splitLocation[1] === 'my-dfg' ? 'state_active' : ''
                  }`}
              >
                <Link
                    underline="none"
                    component={RouterLink}
                    className="headerNavigation__link"
                    to={'/my-dfg'}
                >
                  My Dashboard
                </Link>
              </li>

              <li
                  className={`headerNavigation__item ${
                      splitLocation[1] === 'affiliates' ? 'state_active' : ''
                  }`}
              >
                <Link
                    underline="none"
                    component={RouterLink}
                    className="headerNavigation__link"
                    to={'/affiliates'}
                >
                  Affiliate Partners
                </Link>
              </li>

              {/*<li className={`headerNavigation__item`}>*/}
              {/*  <Button onClick={handleLogout} className="headerNavigation__link">*/}
              {/*    Logout*/}
              {/*  </Button>*/}
              {/*</li>*/}

              <li className={`headerNavigation__item`}>
                <Link
                    onClick={handleLogout}
                    underline="none"
                    component={RouterLink}
                    className="headerNavigation__link"
                    to={'/'}
                >
                  Logout
                </Link>
              </li>

            </ul>
        )}



        {!!user && (
            <div className="headerHamburger">
              <button
                  className={`headerHamburger__btn ${menuOpen ? 'state_active' : ''}`}
                  onClick={() => setMenuOpen(!menuOpen)}
              >
                <span className="headerHamburger__btnLine"></span>
              </button>

              {menuOpen && (
                  <div className="headerHamburger__menu">
                  {/*//   <Button onClick={() => navigate('/')}>*/}
                  {/*//     My Dashboard*/}
                  {/*//   </Button>*/}
                    <Button onClick={handleLogout}>Log Out<img className="headerHamburger__menu__logoutIcon" src={logout} alt={''}/></Button>
                  </div>
              )}
            </div>
        )}
      </div>
    </div>
  );
};

export default HeaderView;
