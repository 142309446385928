import React, {FC, useEffect, useState} from 'react';
import {LinearProgress, linearProgressClasses} from '@mui/material';
import {styled} from '@mui/system';
import SelectSimple from "../../controls/SelectSimple/SelectSimple";
import './ProgressBar.scss';

interface Prop {
    totalItems?: number;
    currentItem?: number;
    isNumberShown?: boolean;
    onSelect?: any;
}

const ProgressBar: FC<Prop> = ({
    totalItems,
    currentItem,
    isNumberShown = false,
    onSelect
}) => {
    const [progressValue, setProgressValue] = useState(0);
    const [selectValue, setSelectValue] = useState(1);

    useEffect(() => {
        if (currentItem !== undefined && currentItem >= 0 && totalItems) {
            if (currentItem + 1 === totalItems) setProgressValue(100);
            else setProgressValue((100 * currentItem) / totalItems);
            setSelectValue(currentItem+1);
        }
    }, [currentItem]);

    const BorderLinearProgress = styled(LinearProgress)(({theme}) => ({
        height: 5,
        borderRadius: 5,
        [`&.${linearProgressClasses.colorPrimary}`]: {
            backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
        },
        [`& .${linearProgressClasses.bar}`]: {
            borderRadius: 5,
            backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
        },
    }));

    const getOptions = (totalItems: any) => {
        const ret = [];
        for (let i = 0; i < totalItems; i++) {
            ret[i] = (i+1).toString();
        }
        return ret;
    }

    return (
        <div className={'stepProgressWrapper'}>
            <div className="stepProgress linearProgressBar" style={{position: 'relative'}}>
                <div
                    style={{width: `${progressValue}%`, position: 'relative'}}
                    className="currentNumber"
                >
                    <div className="currentNumberWrapper">
                        <span>{(currentItem || 0) + 1}</span>
                    </div>
                </div>
                <div className="progressWrapper">
                    <BorderLinearProgress variant="determinate" value={progressValue}/>
                </div>
                <div className="progressPoints">
                    <span>1</span>
                    <span>{totalItems}</span>
                </div>
            </div>
            {(isNumberShown) && (
                <SelectSimple
                    className={'questionNumber'}
                    options={getOptions(totalItems || 0)}
                    title={'Question #'}
                    placeholder={''}
                    onChange={(e: any) => onSelect(e)}
                    value={selectValue}
                />
            )}
        </div>
    );
};

export default ProgressBar;
