import React, {FC, useEffect, useState} from 'react';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select, {SelectChangeEvent} from '@mui/material/Select';
import './SelectSimple.scss'

interface Props {
    name?: string;
    title: string;
    placeholder: string;
    options?: any;
    onChange?: any;
    value?: any;
    className?: string;
    isError?: boolean;
}

const testOptions = [''];

const SelectSimple: FC<Props> = ({
        name= '',
        title,
        placeholder,
        options,
        onChange,
        value,
        className = '',
        isError = false,
    }) => {

    return (
        <div className={className}>
            <FormControl className="selectField">
                <div className="selectField__title">{title}</div>
                <Select
                    name={name}
                    className={`selectField__select ${isError ? 'error' : ''}`}
                    displayEmpty
                    value={value}
                    onChange={onChange}
                    error={isError}
                    renderValue={selected => {
                        if (selected?.length === 0) {
                            return <em>{placeholder}</em>;
                        }
                        return selected;
                    }}
                >
                    {options.map((name: any) => (
                        <MenuItem key={name} value={name}>
                            {name}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </div>
    );
};

export default SelectSimple;
