import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { Navigate } from 'react-router';
import {useAppSelector} from "../hooks/global";
import { ROUTES } from './routes';
import { ThemeProvider } from '@mui/material';
import { theme } from '../assets/styles/themes';
import {
  SignIn,
  ForgotPassword,
  SelectPackage,
  ThxPage,
  Questionnaire,
  MyDFG,
  SetPassword,
  NotFound,
  Affiliates,
  Library,
  LibraryPage,
} from '../components/pages';

const Router = () => {
  const {accessToken, role} = useAppSelector(state => state.auth);
  const isAuthenticated  = !!localStorage.getItem('access_token') || !!accessToken;
  const userRole  = localStorage.getItem('role') || role;

  return (
    <ThemeProvider theme={theme}>
      <Routes>
        <Route path={ROUTES.NOT_FOUND_404} element={<NotFound/>} />
        <Route path={ROUTES.SIGN_IN} element={<SignIn />} />
        <Route path={ROUTES.FORGOT_PASSWORD} element={<ForgotPassword />} />
        <Route path={ROUTES.SET_PASSWORD} element={<SetPassword />} />
        <Route path={ROUTES.SELECT_PACKAGE} element={<SelectPackage />} />
        <Route path={ROUTES.THX_PAGE} element={<ThxPage />} />
        <Route path={ROUTES.QUESTIONNAIRE} element={<Questionnaire />} />
        <Route path={ROUTES.MY_DFG} element={<MyDFG />} />
        <Route path={ROUTES.AFFILIATES} element={<Affiliates />} />
        <Route path={ROUTES.LIBRARY_PAGE} element={<LibraryPage />} />
        <Route path={ROUTES.LIBRARY_ROOT} element={<Library />} />
        <Route path={ROUTES.LIBRARY} element={<Library />} />
        {isAuthenticated && (
            <>
              <Route path="*" element={<NotFound/>} />
            </>
        )}
        <Route path="/" element={<Navigate to={ROUTES.SELECT_PACKAGE} />} />
        <Route path="*" element={<Navigate to="/sign-in" replace />} />
      </Routes>
    </ThemeProvider>
  );
};

export default Router;
