import React, {FC, useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import Checkbox from "@mui/material/Checkbox";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import Button from '@mui/material/Button';
import {FormControlLabel} from "@mui/material";
import {useAppDispatch, useAppSelector} from '../../../hooks/global';
import {fetchQuestions, fetchAnswer, setCurrentAnswer} from "../../../store/questionnaire/questionnaireSlice";
import HeaderView from '../../common/Header/HeaderView';
import FooterView from '../../common/Footer/FooterView';
import TopBanner from '../../common/TopBanner/TopBanner';
import {ConfirmationModal, Help, ProgressBar} from '../../common';
import InputField from "../../controls/InputField/InputField";
import {ReactComponent as CheckboxIcon} from "../../../assets/images/icons/checkbox_ic2.svg";
import {ReactComponent as CheckboxCheckedIcon} from "../../../assets/images/icons/checkbox-checked_ic2.svg";
import Spinner from "../../common/Spinner/Spinner";
import IntroPage from "./parts/IntroPage";
import SelectSimple from "../../controls/SelectSimple/SelectSimple";
import StepImg from "./parts/StepImg";
import guru from "../../../assets/images/icons/guru.svg";
import './Questionnaire.scss';

const Questionnaire: FC = () => {
    const navigation = useNavigate();
    const dispatch = useAppDispatch();
    const {questions, order, last_answer, isLoading} = useAppSelector(item => item.questionnaire);
    const [questionIdx, setQuestionIdx] = useState(0);
    const [discussionOpen, setDiscussionOpen] = useState(false);
    const [discussionText, setDiscussionText] = useState('');
    const [answer, setAnswer] = useState<string>('');
    const [extraAnswer, setExtraAnswer] = useState<string>('');
    const [month, setMonth] = useState<string>('');
    const [year, setYear] = useState<string>('');
    const [answerOptionsIdx, setAnswerOptionsIdx] = useState<Array<any>>([]);
    const [isError, setIsError] = useState<boolean>(false);
    const [isExtraError, setIsExtraError] = useState<boolean>(false);
    const [isQuestionnaireStarted, setIsQuestionnaireStarted] = useState<boolean>(false);
    const [isConfirmModalOpen, setConfirmModalOpen] = useState<boolean>(false);
    const [confirmModalValue, setConfirmModalValue] = useState<string>('');
    const [isIntroductionShown, setIntroductionShown] = useState<boolean>(true);
    const currentQuestion: any = questions[questionIdx] || {};
    const {
        code = 0,
        question = '',
        discussion = '',
        short_discussion = '',
        introduction = '',
        answer_type = '',
        options = '',
        is_discussion = false,
        is_short_discussion = false,
        is_introduction = false
    } = currentQuestion;
    const questionsCount: number = questions ? questions.length : 0;
    const optionsList = (options && !Array.isArray(options)) ? options.split("\n") : [];
    const extraOptions = Array.isArray(options) ? options[1] : [];

    useEffect(() => {
        dispatch(fetchQuestions({packageType: 'package'}));
    }, []);

    useEffect(() => {
        if (order && order?.questionnaire_status === 'new' && order?.last_answer === null) {
            setIsQuestionnaireStarted(false);
        } else{
            setIsQuestionnaireStarted(true);
        }
    }, [order]);

    useEffect(() => {
        if (last_answer && questions) {
            const lastQuestionIdx = questions.findIndex(item => item?.code === last_answer);
            if ((lastQuestionIdx + 1) >= questions.length) {
                setQuestionIdx(lastQuestionIdx);
            } else if (lastQuestionIdx >= 0 && lastQuestionIdx <= questions.length) {
                setQuestionIdx(lastQuestionIdx + 1);
            }
        } else setQuestionIdx(0);
    }, [last_answer]);

    useEffect(() => {
        handleAnswerOptionsToAnswer();
    }, [answerOptionsIdx]);

    useEffect(() => {
        if (month && year) setAnswer(month+' '+year);
    }, [month, year]);

    useEffect(() => {
        if (currentQuestion?.answer?.answer_value) {
            const curAnswers = currentQuestion.answer.answer_value.split("\n");
            const curAnswersIdxs: Array<any> = [];
            curAnswers.forEach((element: any) => {
                const item: any = optionsList.find((item: any) => item === element);
                if (item) curAnswersIdxs.push(optionsList.indexOf(item));
            });
            if (curAnswersIdxs.length) {
                setAnswerOptionsIdx(curAnswersIdxs);
            }
            if (currentQuestion?.answer_type === 'date') {
                const curAnswers = currentQuestion.answer.answer_value.split(" ");
                setMonth(curAnswers[0].trim());
                setYear(curAnswers[1]);
            }
        }
    }, [questionIdx]);

    useEffect(() => {
        setExtraAnswer('');
        setDiscussionText((!discussionOpen && (is_short_discussion && short_discussion.length)) ? `${short_discussion}...` : discussion);
        if (currentQuestion?.answer?.answer_value) {
            setAnswer(currentQuestion.answer.answer_value);
            if (currentQuestion?.answer_type === 'bool-extra' && currentQuestion?.answer?.answer_value !== '0') {
                setExtraAnswer(currentQuestion?.answer?.answer_value);
            } else if (currentQuestion?.code === 217) {
                handleGet217(currentQuestion.answer.answer_value);
            }
        } else {
            setAnswer('');
            setExtraAnswer('');
        }
    }, [currentQuestion]);

    const handleAnswerOptionsToAnswer = () => {
        if (answer_type === 'checkboxes' && answerOptionsIdx.length >= 0) {
            answerOptionsIdx.sort();
            let answerVal = ''
            answerOptionsIdx.forEach(element => {
                answerVal = answerVal + "\n" + optionsList[element];
            });
            setAnswer(answerVal);
        }
    }

    const handleDiscussion = (e: any) => {
        e.preventDefault();
        setDiscussionText(discussionOpen
            ? ((is_short_discussion && short_discussion.length) ? `${short_discussion}...` : discussion)
            : discussion
        );
        setDiscussionOpen(!discussionOpen);
    };

    const handleAnswerChange = (e: any) => {
        const {name, value, checked} = e.target || e;
        setIsError(false);
        setIsExtraError(false);
        if (name == 'answer_input' || name == 'answer_memo' || name == 'answer_bool') {
            setAnswer(name == 'answer_bool' ? (value === 'yes' ? '1' : '0') : value);
            if (code !== 217) setExtraAnswer('');
        } else if (name == 'answer_radio') {
           if (checked) setAnswer(value);
        } else if (name == 'answer_checkbox') {
            const valueIdx = optionsList.indexOf(value);
            if (valueIdx >= 0) {
                setAnswerOptionsIdx(checked ? [...answerOptionsIdx, valueIdx] : answerOptionsIdx.filter(e => e !== valueIdx));
                handleAnswerOptionsToAnswer();
            }
        }
    }

    const handleExtraAnswerChange = (e: any) => {
        const {name, value} = e.target || e;
        setIsError(false);
        setIsExtraError(false);
        if (name == 'answer_bool_extra' || name == 'answer_input_extra') {
            setExtraAnswer(value);
        }
    }

    const handleAnswerChangeDate = (type: any, e: any) => {
        const value = e?.target.value;
        setIsError(false);
        if (type === 'month') setMonth(value);
        else if (type === 'year') setYear(value);
    }

    const handleSave = (type = 'next') => {
        if (!isError && answer) {

            const handleAnswer = () => {
                if (answer_type === 'bool-extra' && extraOptions.length && answer !== '0') {
                    return extraAnswer;
                } else if (code===217) {
                    return (extraAnswer) ? (answer+"\n"+extraAnswer) : answer;
                } else {
                    return answer;
                }
            }

            if (answer_type !== 'bool-extra' || extraOptions.length === 0 || extraAnswer || answer === '0') {
                dispatch(fetchAnswer({
                    question_code: code,
                    answer_type: answer_type,
                    //answer_value: (answer_type === 'bool-extra' && extraOptions.length && answer !== '0') ? extraAnswer : answer,
                    answer_value: handleAnswer(),
                    is_read_more: discussionOpen,
                    order_id: order.id,
                    is_finish: ((questionIdx + 1) >= questions.length || type==='finish'),
                })).then(resp => {
                    if (resp.type === 'question/answer/fulfilled') {
                        dispatch(setCurrentAnswer({idx: questionIdx, value: handleAnswer()}));
                        reset();
                        if ((type == 'later' || type==='finish') || (questionIdx + 1) >= questions.length) {
                            navigation('/my-dfg');
                        } else setQuestionIdx(questionIdx + 1);
                    }
                });

            } else setIsExtraError(true);

        } else setIsError(true);
    }

    const handleBack = () => {
        if (questionIdx > 0) {
            reset();
            setQuestionIdx(questionIdx - 1);
        }
    }

    const reset = () => {
        setAnswer('');
        setMonth('');
        setYear('');
        setAnswerOptionsIdx([]);
        setIsError(false);
        setDiscussionOpen(false);
        setIntroductionShown(true);
    }

    const handleStart = () => {
        setIsQuestionnaireStarted(true);
    }

    const getPackageTitle = (alias: any) => {
        if (alias === 'nonreg-dot-fmcsa-reg-docs') return 'Drug Free Workplace Policy, Drug Free Workplace Policy Summary, Forms Section, Controlled Substances & Alcohol Testing Policy, Controlled Substances & Alcohol Testing 10 Point Summary, and Glossary of Terms';
        else if (alias === 'dot-fmcsa-reg-docs') return 'Controlled Substances & Alcohol Testing Policy, Controlled Substances & Alcohol Testing 10 Point Summary, and Glossary of Terms';
        else if (alias === 'nonreg-docs') return 'Drug Free Workplace Policy, Drug Free Workplace Policy Summary, Forms Section, and Glossary of Terms';
        else return '';
    }

    const getHeaderTitle = (alias: any) => {
        if (alias === 'nonreg-dot-fmcsa-reg-docs') return 'NON-REGULATED & DOT/FMCSA QUESTIONNAIRE';
        else if (alias === 'dot-fmcsa-reg-docs') return 'DOT/FMCSA QUESTIONNAIRE';
        else if (alias === 'nonreg-docs') return 'NON-REGULATED QUESTIONNAIRE';
        else return '';
    }

    const getValue = (value: string): boolean => {
        const valueIdx = optionsList.indexOf(value);
        return answerOptionsIdx.find(item => item === valueIdx) >= 0;
    }

    const handleQuestionSelect = (e: any) => {
        const prevAnswer = currentQuestion?.answer?.answer_value || '';
        const value = e?.target.value;
        if (new String(answer).valueOf().trim() === new String(prevAnswer).valueOf().trim()) {
            reset();
            setQuestionIdx(parseInt(value)-1);
        } else {
            setConfirmModalValue(value);
            setConfirmModalOpen(true);
        }
    }

    const handleChangeQuestion = () => {
        setConfirmModalOpen(false);
        if (confirmModalValue) {
            reset();
            setQuestionIdx(parseInt(confirmModalValue)-1);
        }
    }

    const getYears = () => {
        const ret = [];
        const year = 2023;
        for (let i = year; i < year+5; i++) {
            ret[i] = i.toString();
        }
        return ret;
    }

    const handleGet217 = (val: string):void => {
        if (val) {
            const arr = val.split("\n");
             setAnswer(arr[0]);
            if (arr[1]) setExtraAnswer(arr[1]);
            else setExtraAnswer('');
        }
    }

    const handleQuestionTitle = (title: string) => {
        return title.replace('with company personnel', '<u>with company personnel</u>');
    }

    return (
        <div className="contentWrapper">
            <HeaderView/>
            <Spinner isLoading={isLoading}/>
            <div className="pageContainer">
                <TopBanner title={getHeaderTitle(order?.package)}/>
                <div className="pageContainer__view progressContainer">
                    <div className="pageHeader">
                        <div className="pageHeader__rowTitle">
                            <h2 className="pageHeader__title -detailsStyle">
                                {order?.package_name}
                            </h2>
                        </div>
                        <div className="pageHeader__rowSubTitle">
                            {getPackageTitle(order?.package)}
                        </div>
                    </div>
                    {isQuestionnaireStarted ? (
                        <>
                            {(is_introduction && isIntroductionShown) ? (
                                <>
                                    <div className={'intruduction'} dangerouslySetInnerHTML={{__html: introduction}}/>
                                    <div className="imageAndBtns__wrapper">
                                        <div className="stepContent__btnWrapper">
                                            <Button
                                                onClick={handleBack}
                                                variant="contained"
                                                className="stepContent__btn simpleBtn -border"
                                            >
                                                BACK
                                            </Button>
                                            <Button
                                                onClick={() => setIntroductionShown(false)}
                                                variant="contained"
                                                className="stepContent__btn simpleBtn"
                                            >
                                                CONTINUE
                                            </Button>
                                        </div>
                                    </div>
                                </>
                            ) : (
                                <>
                                    <ProgressBar
                                        isNumberShown={!!order?.is_questionnaire_completed || false}
                                        totalItems={questionsCount}
                                        currentItem={questionIdx}
                                        onSelect={(e: any) => handleQuestionSelect(e)}
                                    />
                                    <div className="stepContent">
                                        <div className="stepContent__questionWrapper mt-0">
                                            <h3 className={"question"} dangerouslySetInnerHTML={{__html: handleQuestionTitle(question)}}></h3>
                                            <div className={`answer ${isError ? 'error' : ''}`}>
                                                {(answer_type === 'string') && (
                                                    <>
                                                        <InputField
                                                            name={'answer_input'}
                                                            onChange={handleAnswerChange}
                                                            error={isError}
                                                            value={answer}
                                                        />
                                                        {(code===217) && (
                                                            <>
                                                                <br/>
                                                                <InputField
                                                                    name={'answer_input_extra'}
                                                                    onChange={handleExtraAnswerChange}
                                                                    error={isError}
                                                                    value={extraAnswer}
                                                                />
                                                            </>
                                                        )}
                                                    </>
                                                )}
                                                {(answer_type === 'bool' || answer_type === 'bool-extra') && (
                                                    <>
                                                        <RadioGroup
                                                            className={`answer__bool ${isError ? 'error' : ''}`}
                                                            row
                                                            aria-labelledby="row-radio-buttons-group-label"
                                                            name="row-radio-buttons-group"
                                                        >
                                                            <FormControlLabel
                                                                name={'answer_bool'}
                                                                checked={(answer !== '0' && answer !== '')}
                                                                value="yes"
                                                                control={
                                                                    <Radio onClick={handleAnswerChange}/>
                                                                }
                                                                label={'Yes'}
                                                            />
                                                            <FormControlLabel
                                                                name={'answer_bool'}
                                                                checked={answer === '0'}
                                                                value="no"
                                                                control={
                                                                    <Radio onClick={handleAnswerChange}/>
                                                                }
                                                                label={'No'}
                                                            />
                                                        </RadioGroup>

                                                        {(answer_type === 'bool-extra' && (answer !== '0' && answer !== '')) && (
                                                            <div className={"extraQuestion"}>
                                                                <RadioGroup
                                                                    className={`extraQuestionRadios ${isExtraError ? 'error' : ''}`}
                                                                    row
                                                                    aria-labelledby="row-radio-buttons-group-label"
                                                                    name="row-radio-buttons-group"
                                                                >
                                                                    {extraOptions[0] && (
                                                                        <FormControlLabel
                                                                            name={'answer_bool_extra'}
                                                                            checked={extraAnswer === '1'}
                                                                            value={1}
                                                                            control={
                                                                                <Checkbox onClick={handleExtraAnswerChange}/>
                                                                            }
                                                                            label={extraOptions[0]}
                                                                        />
                                                                    )}
                                                                    {extraOptions[1] && (
                                                                        <FormControlLabel
                                                                            name={'answer_bool_extra'}
                                                                            checked={extraAnswer === '2'}
                                                                            value={2}
                                                                            control={
                                                                                <Checkbox onClick={handleExtraAnswerChange}/>
                                                                            }
                                                                            label={extraOptions[1]}
                                                                        />
                                                                    )}
                                                                </RadioGroup>
                                                            </div>
                                                        )}
                                                    </>
                                                )}
                                                {(answer_type === 'checkboxes') && (
                                                    <div className={`answer__checkboxes ${isError ? 'error' : ''}`}>
                                                        {optionsList.map((item: any, idx: number) => (
                                                            <FormControlLabel
                                                                key={idx}
                                                                className="authCheckbox"
                                                                name={'answer_checkbox'}
                                                                control={
                                                                    <Checkbox
                                                                        onClick={handleAnswerChange}
                                                                        icon={<CheckboxIcon/>}
                                                                        checkedIcon={<CheckboxCheckedIcon/>}
                                                                        checked={getValue(item)}
                                                                        value={item}
                                                                    />
                                                                }
                                                                label={item}
                                                            />
                                                        ))}
                                                    </div>
                                                )}
                                                {(answer_type === 'radios') && (
                                                    <RadioGroup
                                                        className={`answer__bool ${isError ? 'error' : ''}`}
                                                        row={false}
                                                        aria-labelledby="row-radio-buttons-group-label"
                                                        name="row-radio-buttons-group"

                                                    >
                                                        {optionsList.map((item: any, idx: number) => (
                                                            <FormControlLabel
                                                                key={idx}
                                                                className="authCheckbox"
                                                                name={'answer_radio'}
                                                                control={
                                                                    <Radio
                                                                        onClick={handleAnswerChange}
                                                                        checked={answer === item}
                                                                        value={item}
                                                                    />
                                                                }
                                                                label={item}
                                                            />
                                                        ))}
                                                    </RadioGroup>
                                                )}
                                                {(answer_type === 'memo') && (
                                                    <TextareaAutosize
                                                        name={'answer_memo'}
                                                        onChange={handleAnswerChange}
                                                        className={`textareaField__textarea answer__${answer_type} ${isError ? 'error' : ''}`}
                                                        maxRows={10}
                                                        value={answer}
                                                    />
                                                )}
                                                {(answer_type === 'date') && (
                                                    <div className={'date'}>
                                                        <SelectSimple
                                                            name={'month'}
                                                            title={'Month'}
                                                            placeholder={'Month'}
                                                            options={['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']}
                                                            onChange={(val:any)=>handleAnswerChangeDate('month',val)}
                                                            value={month}
                                                            isError={isError && !month}
                                                        />
                                                        <SelectSimple
                                                            name={'year'}
                                                            title={'Year'}
                                                            placeholder={'Year'}
                                                            options={getYears()}
                                                            onChange={(val:any)=>handleAnswerChangeDate('year',val)}
                                                            value={year}
                                                            isError={isError && !year}
                                                        />
                                                    </div>
                                                )}
                                            </div>

                                            {(questionIdx === 0 && answer_type) && (
                                                <div className={"important"}>
                                                    <h4>Important!</h4>
                                                    <p>Before answering each question, it is important to be aware most questions have a Discussion Section that provides valuable information and considerations that can assist you with making sound decisions for your organization.</p>
                                                    <p>It is highly recommended to always read the Discussion Sections <span>before</span> providing your answers.</p>
                                                </div>
                                            )}

                                            {(is_discussion && discussion) ? (
                                                <div className="stepContent__discussionWrapper">
                                                    <div className="stepContent__discussionLabel"><img src={guru} alt={''}/>Guru Guidance</div>
                                                    <div className="stepContent__discussionContent">
                                                        <div className="stepContent__discussionText">
                                                            <div dangerouslySetInnerHTML={{__html: discussionText}} className={'stepContent__discussionDesc'}/>
                                                            {(is_short_discussion === 1) && (
                                                                <a
                                                                    className={`stepContent__discussionMoreLink`}
                                                                    href="#"
                                                                    onClick={(e) => handleDiscussion(e)}
                                                                >
                                                                    {`${discussionOpen ? 'Collapse Info' : 'Read More'}`}
                                                                </a>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            ) : null
                                            }
                                        </div>
                                        <div className="imageAndBtns__wrapper">
                                            <div className="stepContent__btnWrapper">
                                                <Button
                                                    onClick={handleBack}
                                                    variant="contained"
                                                    className="stepContent__btn simpleBtn -border"
                                                    disabled={questionIdx === 0 || isLoading}
                                                >
                                                    BACK
                                                </Button>
                                                {(questions.length <= questionIdx + 1) ? (
                                                    <Button
                                                        onClick={() => {
                                                            handleSave('next')
                                                        }}
                                                        variant="contained"
                                                        className="stepContent__btn simpleBtn -border"
                                                        disabled={isLoading}
                                                    >
                                                        SAVE & COMPLETE!
                                                    </Button>
                                                ) : (
                                                    <>
                                                        <Button
                                                            onClick={() => {
                                                                handleSave('next')
                                                            }}
                                                            variant="contained"
                                                            className="stepContent__btn simpleBtn -border"
                                                            disabled={isLoading}
                                                        >
                                                            SAVE & NEXT
                                                        </Button>
                                                        {(order?.is_questionnaire_completed === 1) ? (
                                                            <Button
                                                                onClick={() => {
                                                                    handleSave('finish')
                                                                }}
                                                                variant="contained"
                                                                className="stepContent__btn simpleBtn"
                                                                disabled={isLoading}
                                                            >
                                                                Save & COMPLETE
                                                            </Button>
                                                        ) : (
                                                            <Button
                                                                onClick={() => {
                                                                    handleSave('later')
                                                                }}
                                                                variant="contained"
                                                                className="stepContent__btn simpleBtn"
                                                                disabled={isLoading}
                                                            >
                                                                Save & CONTINUE LATER
                                                            </Button>
                                                        )}

                                                    </>
                                                )}
                                            </div>
                                            <StepImg
                                                alias={order?.package}
                                                index={(questionIdx + 1)}
                                            />
                                        </div>
                                    </div>
                                </>
                            )}
                        </>
                    ) : (
                        <IntroPage
                            order={order}
                            handleStart={handleStart}
                        />
                    )}
                </div>
            </div>
            <ConfirmationModal
                modalIsOpen={isConfirmModalOpen}
                onConfirm={handleChangeQuestion}
                onCancel={()=>{
                    setConfirmModalValue('');
                    setConfirmModalOpen(false)
                }}
                modalText={'Do you really want to continue without saving your data?'}
            />
            <FooterView/>
        </div>
    );
};

export default Questionnaire;