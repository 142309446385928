import React, { FC } from 'react';
import bg from '../../../assets/images/topBanner__bg2.jpg';
import bgAffiliates from '../../../assets/images/topBanner_bg_affiliates.jpg';
import bgMobile from '../../../assets/images/topBanner-mobile__bg2.jpg';
import './TopBanner.scss'

interface Prop {
    title?: string;
    page?: string;
}

const TopBanner: FC<Prop> = ({title = '', page = ''}) => {

  return (
      <div className="topBanner">
        <h1 className={'title'}>{title}</h1>
        <img
            className="topBanner__img -desktop"    
            src={page==='affiliates' ? bgAffiliates : bg}
            alt=""
        />
        <img
            className="topBanner__img -mobile"
            src={page==='affiliates' ? bgAffiliates : bgMobile}
            alt=""
        />
      </div>
  );
};

export default TopBanner;
