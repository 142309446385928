import React, {FC, useEffect} from 'react';
import {useLocation, useNavigate} from "react-router-dom";
import Button from "@mui/material/Button";
import HeaderView from '../../common/Header/HeaderView'
import FooterView from '../../common/Footer/FooterView'
import TopBanner from '../../common/TopBanner/TopBanner'
import './ThxPage.scss'

const ThxPage: FC = () => {

    const location = useLocation();
    const navigate = useNavigate();
    const packageType = location?.state?.packageType || null;
    const price = location?.state?.price || 0;

    useEffect(() => {
        if (!packageType && !price) navigate("/sign-in");
    }, [packageType, price]);

    return (
        <div className="contentWrapper">
            <HeaderView/>

            <div className="pageContainer">
                <TopBanner/>

                <div className="pageContainer__view">

                    <div className="pageHeader">
                        <div className="pageHeader__rowTitle">
                            <h2 className="pageHeader__title -detailsStyle">Thanks for your order!</h2>
                        </div>
                        <div className="pageHeader__rowSubTitle">
                            Thank you for your purchase. Please find your order summary below.
                        </div>
                    </div>

                    <div className="thxPage">
                        <h3 className="thxPage__title">Order Summary</h3>

                        {packageType === 'nonreg-docs' && (
                            <>
                                <p className="thxPage__text">
                                    Non-Regulated Documents
                                </p>
                                <h4 className="thxPage__subTitle">What’s included:</h4>
                                <ul className="thxPage__list">
                                    <li className="thxPage__item">
                                        Drug-Free Workplace Policy
                                    </li>
                                    <li className="thxPage__item">
                                        Drug-Free Workplace Policy Summary
                                    </li>
                                    <li className="thxPage__item">
                                        Forms Section
                                    </li>
                                    <li className="thxPage__item">
                                        Glossary of Terms
                                    </li>
                                </ul>
                            </>
                        )}

                        {packageType === 'dot-fmcsa-reg-docs' && (
                            <>
                                <p className="thxPage__text">
                                    DOT/FMCSA Regulated Documents
                                </p>
                                <h4 className="thxPage__subTitle">What’s included:</h4>
                                <ul className="thxPage__list">
                                    <li className="thxPage__item">
                                        Controlled Substances & Alcohol Testing Policy
                                    </li>
                                    <li className="thxPage__item">
                                        Controlled Substances & Alcohol Testing 10-Point Summary
                                    </li>
                                    <li className="thxPage__item">
                                        Glossary of Terms
                                    </li>
                                </ul>
                            </>
                        )}

                        {packageType === 'nonreg-dot-fmcsa-reg-docs' && (
                            <>
                                <p className="thxPage__text">
                                    Non-Regulated Documents and DOT/FMCSA Regulated Documents
                                </p>
                                <h4 className="thxPage__subTitle">What’s included:</h4>
                                <ul className="thxPage__list">
                                    <li>
                                        <h3 className="thxPage__ulSubTitle">Non-Regulated Documents</h3>
                                        <ul className="thxPage__list">
                                            <li className="thxPage__item">
                                                Drug-Free Workplace Policy
                                            </li>
                                            <li className="thxPage__item">
                                                Drug-Free Workplace Policy Summary
                                            </li>
                                            <li className="thxPage__item">
                                                Forms Section
                                            </li>
                                            <li className="thxPage__item">
                                                Glossary of Terms
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <h4 className="thxPage__ulSubTitle">DOT/FMCSA Regulated Documents</h4>
                                        <ul className="thxPage__list">
                                            <li className="thxPage__item">
                                                Controlled Substances & Alcohol Testing Policy
                                            </li>
                                            <li className="thxPage__item">
                                                Controlled Substances & Alcohol Testing 10-Point Summary
                                            </li>
                                            <li className="thxPage__item">
                                                Glossary of Terms
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                            </>
                        )}

                        <div className="thxPage__totalWrapper">
                            <div className="thxPage__totalTitle">Total:</div>
                            <div className="thxPage__totalValue">{`$${price.toFixed(2)}`}</div>
                        </div>

                        <p className="thxPage__endText">
                            You will be receiving a confirmation email with order details as well as your link to create your account. If the email does not arrive in a timely manner check your SPAM folder.
                        </p>

                        {/*<Button*/}
                        {/*    id={'to_login'}*/}
                        {/*    onClick={() => {*/}
                        {/*        navigate('/sign-in')*/}
                        {/*    }}*/}
                        {/*    variant="contained"*/}
                        {/*    className="simpleBtn">*/}
                        {/*    Go to Login Page*/}
                        {/*</Button>*/}

                    </div>
                </div>
            </div>

            <FooterView/>
        </div>
    );
};

export default ThxPage;
