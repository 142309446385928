import React, {FC} from 'react';
import step0Img from '../../../../assets/images/steps/step0.svg';
import step1Img from '../../../../assets/images/steps/step1.svg';
import step2Img from '../../../../assets/images/steps/step2.svg';
import step3Img from '../../../../assets/images/steps/step3.svg';
import step4Img from '../../../../assets/images/steps/step4.svg';

type Props = {
    alias: string,
    index: number,
};

const StepImg: FC<Props> = ({alias, index}) => {
    const getStepImg = () => {
        if (index === 1) return step0Img;
        else {
            if (alias === 'nonreg-dot-fmcsa-reg-docs') {
                if (index === 10) return step1Img;
                else if (index === 20) return step2Img;
                else if (index === 30) return step3Img;
                else if (index === 46) return step4Img;
            } else if (alias === 'dot-fmcsa-reg-docs') {
                if (index === 3) return step1Img;
                else if (index === 6) return step2Img;
                else if (index === 9) return step3Img;
                else if (index === 11) return step4Img;
            } else if (alias === 'nonreg-docs') {
                if (index === 10) return step1Img;
                else if (index === 20) return step2Img;
                else if (index === 30) return step3Img;
                else if (index === 41) return step4Img;
            }
        }
        return null;
    }

    return (
        (getStepImg()) ? (
            <div className="progressImage">
                <img src={getStepImg()} alt="Logo" className="mainLogo"/>
            </div>
        ) : null
    );
};

export default StepImg;
