import axios from 'axios';
import {API_URI} from '../constants/api';
import {toast} from 'react-toastify';
import {ErrorToast} from "../components/common";
import { store } from '../store/store';
import {resetTokens} from "../store/auth/authSlice";

const configureApi = () => {

    let refreshingToken:any = null;

    function refreshToken() {
        const refresh_token = localStorage.getItem('refresh_token');
        return instance.post("general/refresh-token", {refresh_token});
    }

    const instance = axios.create({
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json; charset=utf-8',
            'X-Requested-With': 'XMLHttpRequest',
            'App-Key': '6d522d89d9fa551752726113b5899f',
        },
        baseURL: API_URI,
    });

    instance.interceptors.request.use(function (config) {
        const accessToken = localStorage.getItem('access_token');
        if (accessToken && config.headers) {
            config.headers.Authorization = accessToken ? `Bearer ${accessToken}` : '';
        }
        return config;
    });

    instance.interceptors.response.use((response) => {
        return response.data
    }, async (error) => {
        const config = error.config;
        if (error.response && error.response.status === 401 && !config._retry) {
            config._retry = true;
            try {
                refreshingToken = refreshingToken ? refreshingToken : refreshToken();
                const res = await refreshingToken;
                refreshingToken = null;
                if(res.data.resource.access_token) {
                    const {access_token, refresh_token} = res.data.resource;
                    localStorage.setItem('access_token', access_token);
                    localStorage.setItem('refresh_token', refresh_token);
                }
                return instance(config);
            } catch (error) {
                store.dispatch(resetTokens());
                window.location.replace('/sign-in');
            }
        } else if (error.response && error.response.status === 400) {
            if (error.config.url !== 'general/refresh-token' && error.config.url !== 'general/logout') {
                toast.error(<ErrorToast
                    message={error.response?.data?.message}
                    errors={error.response?.data?.errors}
                />);
            }
        } else {
            toast.error('Server Error.');
        }
        //return Promise.reject(error)
    });

    return instance;
};

const api = configureApi();
export default api;
