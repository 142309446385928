import React, {FC, useEffect, useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../../../hooks/global";
import HeaderView from '../../common/Header/HeaderView'
import FooterView from '../../common/Footer/FooterView'
import TopBanner from '../../common/TopBanner/TopBanner'
import {fetchPage} from "../../../store/library/librarySlice";
import FadeLoader from "react-spinners/FadeLoader";
import Button from "@mui/material/Button";
//import { Document, Page } from 'react-pdf/dist/esm/entry.webpack5';

import { Document, Page, pdfjs  } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
pdfjs.GlobalWorkerOptions.workerSrc = '../../../pdf.worker.min.js';

import './LibraryPage.scss'


const LibraryPage: FC = () => {

    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const {alias} = useParams();
    const {page, isLoading} = useAppSelector(state => state.library);
    const [isIframeLoading, setIsIframeLoading] = useState(true);
    const [numPages, setNumPages] = useState(null);
    const [pdfWidth, setPdfWidth] = useState(1060);

    function onDocumentLoadSuccess(item: any) {
        const { numPages } = item;
        setNumPages(numPages);
        setIsIframeLoading(false);
    }

    useEffect(() => {
        if (alias) {
            dispatch(fetchPage(alias)).then(resp => {
                if (resp?.type === 'library/page/rejected') {
                    navigate('/404')
                }
            });
        } else navigate('/404')
    }, [alias]);

    const handleIframeLoad = () => {
        setIsIframeLoading(false);
    }

    const handleResize = () => {
        setPdfWidth(window.innerWidth > 1060 ? 1060 : window.innerWidth)
    }

    window.addEventListener('resize', handleResize)

    return (
        <div className="contentWrapper">
            <HeaderView/>

            <div className="pageContainer">
                <TopBanner title={`${!isLoading ? (page?.category.alias==='federal-and-state-resources' ? 'Federal & State Resources' : 'Library') : ''}`}/>
                <div className="pageContainer__view">
                    <div className="pageHeader">
                        <div className="pageHeader__rowTitle">
                            <h2 className="pageHeader__title">{page?.title}</h2>
                            <Button
                                onClick={() => navigate(-1)}
                                variant="contained"
                                className="btnEdit BackBtn"
                            >
                                Back
                            </Button>
                        </div>
                    </div>
                    {(page && page?.type === 'page') && (
                        <div className={'pageContent'} dangerouslySetInnerHTML={{__html: page?.content}}/>
                    )}
                    {(page && page?.type === 'document' && page?.document && page?.document?.file) && (
                        <>
                            {(page?.document?.ext === "pdf") && (
                                <div className={'iframeContainer'}>
                                    <div className={'iframeSpinner'}>
                                        <FadeLoader
                                            color={'#1e96fc'}
                                            loading={isIframeLoading}
                                            aria-label="Loading Spinner"
                                        />
                                    </div>

                                    <Document
                                        className={'pdfDocument'}
                                        file={page.document.file}
                                        onLoadSuccess={onDocumentLoadSuccess}
                                        renderMode={'canvas'}
                                    >
                                        {Array.from(new Array(numPages), (el, index) => (
                                            <Page
                                                className={'pdfDocumentPage'}
                                                key={index}
                                                pageNumber={index + 1}
                                                width={pdfWidth}
                                                renderAnnotationLayer={false}
                                                renderTextLayer={false}
                                            />
                                        ))}
                                    </Document>
                                </div>
                            )}

                            {(page?.document?.ext === 'doc' || page?.document?.ext === 'docx') && (
                                <div className={'iframeContainer'}>
                                    <div className={'iframeSpinner'}>
                                        <FadeLoader
                                            color={'#1e96fc'}
                                            loading={isIframeLoading}
                                            aria-label="Loading Spinner"
                                        />
                                    </div>
                                    <iframe
                                        className={'iframeContainerDoc'}
                                        src={`https://view.officeapps.live.com/op/embed.aspx?src=${page.document.file}`}
                                        width={"100%"}
                                        height={"800"}
                                        frameBorder={"0"}
                                        onLoad={handleIframeLoad}
                                    />
                                </div>
                            )}
                        </>
                    )}
                </div>
            </div>
            <FooterView/>
        </div>
    );
};

export default LibraryPage;
