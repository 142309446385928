import React, {FC, useEffect} from 'react';
import {useNavigate} from "react-router-dom";
import {useAppDispatch, useAppSelector} from '../../../hooks/global';
import {fetchMyDfg} from "../../../store/customer/customerSlice";
import {fetchDocDownload, fetchResetQuestionnaire} from "../../../store/questionnaire/questionnaireSlice";
import HeaderView from '../../common/Header/HeaderView';
import FooterView from '../../common/Footer/FooterView';
import TopBanner from '../../common/TopBanner/TopBanner';
import Button from '@mui/material/Button';
import Spinner from "../../common/Spinner/Spinner";
import NonRegDoc from '../../../assets/images/icons/doc_non_reg.svg';
import RegDoc from '../../../assets/images/icons/doc_reg.svg';
import Library from '../../../assets/images/icons/library.svg';
import Newsletter from '../../../assets/images/icons/newsletter.svg';
import Affiliate from '../../../assets/images/icons/affiliate.svg';
import AffiliateImg from '../../../assets/images/affiliates_img.jpg';
import './MyDFG.scss';

const MyDFG: FC = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const {order} = useAppSelector(state => state.customer);
    const {isLoading: isQuestionnaireLoading, isLoading} = useAppSelector(state => state.questionnaire);

    useEffect(() => {
        dispatch(fetchMyDfg());
    }, []);

    const handleDownload = (docType: string) => {
        if (!isLoading) dispatch(fetchDocDownload(docType));
    };

    const handleEditQuestionnaire = () => {
        navigate('/questionnaire');
        // dispatch(fetchResetQuestionnaire()).then(resp => {
        //     if (resp?.type === 'question/reset/fulfilled') {
        //         navigate('/questionnaire');
        //     }
        // });
    };

    return (
        <div className="contentWrapper">
            <HeaderView/>
            <Spinner isLoading={isLoading || isQuestionnaireLoading}/>
            <div className="pageContainer">
                <TopBanner title={'RESOURCE CENTER'}/>
                <div className="pageContainer__view">
                    <div className="pageHeader">
                        <div className="pageHeader__rowTitle">
                            <h2 className="pageHeader__title">My Drug Free Guru</h2>
                            {(order?.questionnaire_status === 'finished') && (
                                <Button
                                    onClick={handleEditQuestionnaire}
                                    variant="contained"
                                    className="docCardBtn btnEdit"
                                >
                                    Modify Documents
                                </Button>
                            )}
                        </div>
                    </div>
                    {(order?.questionnaire_status === 'new' || order?.questionnaire_status === 'in-progress') ? (
                        <div className="questionnaireBth">
                            <Button
                                onClick={() => {
                                    navigate("/questionnaire", {state: {packageType: order.package}})
                                }}
                                variant="contained"
                                className="stepContent__btn simpleBtn -border"
                            >
                                {`${(order?.questionnaire_status === 'in-progress') ? 'Continue' : 'Start'} Questionnaire "${order?.package_name}"`}
                            </Button>
                        </div>
                    ) : null}
                    {(order?.questionnaire_status === 'finished') ? (
                        <>
                            <div className="infoBlock">
                                <div className="infoBlock__text">
                                    <p>
                                        Updating your Drug Free Guru documents is a straightforward process. Just click on the “Modify Documents” button and proceed to the specific question(s) you wish to revise. When you’re prepared to obtain your update policy documents, head over to the Dashboard section and select the “Download” option. This will initiate the download of all of your policy files to your computer, and you can easily view them using Adobe PDF format.
                                    </p>
                                    <p>
                                        If you’re interested in learning how the Affiliate Partners may be able to assist you in creating your comprehensive program, scroll down to receive your Affiliate Partner code and then click on contact Affiliate.
                                    </p>
                                </div>
                            </div>
                            {(order?.package === 'nonreg-docs' || order?.package === 'nonreg-dot-fmcsa-reg-docs') ? (
                                <div className="docsWrapper NonRegDoc">
                                    <div className="docsHeader">Non-Regulated Documents</div>
                                    <div className="docCardsGroup">

                                        <div className="docCard">
                                           <img className="docCardIcon" src={NonRegDoc} alt="NonRegDoc"/>
                                           <h2>Part 1</h2>
                                           <h3>Document</h3>
                                           <hr/>
                                           <h4>Drug-Free Workplace Policy</h4>
                                           <h5>&nbsp;</h5>
                                           <Button
                                               variant="contained"
                                               className="docCardBtn"
                                               onClick={()=>handleDownload('workplace-policy')}
                                               disabled={isLoading}>
                                               <span className="drugFreeList__btnIcon"/>
                                               download
                                           </Button>
                                        </div>

                                        <div className="docCard">
                                            <img className="docCardIcon" src={NonRegDoc} alt="NonRegDoc"/>
                                            <h2>Part 2</h2>
                                            <h3>Document</h3>
                                            <hr/>
                                            <h4>Drug-Free Workplace Policy Summary</h4>
                                            <h5>&nbsp;</h5>
                                            <Button
                                                variant="contained"
                                                className="docCardBtn"
                                                onClick={()=>handleDownload('workplace-policy-summary')}
                                                disabled={isLoading}>
                                                <span className="drugFreeList__btnIcon"/>
                                                download
                                            </Button>
                                        </div>

                                        <div className="docCard">
                                            <img className="docCardIcon" src={NonRegDoc} alt="NonRegDoc"/>
                                            <h2>Part 3</h2>
                                            <h3>Document</h3>
                                            <hr/>
                                            <h4>Forms Selection</h4>
                                            <h5>&nbsp;</h5>
                                            <Button
                                                variant="contained"
                                                className="docCardBtn"
                                                onClick={()=>handleDownload('forms-section')}
                                                disabled={isLoading}>
                                                <span className="drugFreeList__btnIcon"/>
                                                download
                                            </Button>
                                        </div>

                                        <div className="docCard">
                                            <img className="docCardIcon" src={NonRegDoc} alt="NonRegDoc"/>
                                            <h2>Part 4</h2>
                                            <h3>Document</h3>
                                            <hr/>
                                            <h4>Glossary of Terms</h4>
                                            <h5>&nbsp;</h5>
                                            <Button
                                                variant="contained"
                                                className="docCardBtn"
                                                onClick={()=>handleDownload('glossary-non-reg')}
                                                disabled={isLoading}>
                                                <span className="drugFreeList__btnIcon"/>
                                                download
                                            </Button>
                                        </div>

                                    </div>
                                </div>
                            ) : null}

                            {(order?.package === 'dot-fmcsa-reg-docs' || order?.package === 'nonreg-dot-fmcsa-reg-docs') ? (
                                <div className="docsWrapper">
                                    <div className="docsHeader RegDoc">Regulated Documents</div>
                                    <div className="docCardsGroup">

                                        <div className="docCard RegDoc">
                                            <img className="docCardIcon" src={RegDoc} alt="RegDoc"/>
                                            <h2>Part 1</h2>
                                            <h3>DOT/ FMCSA Document</h3>
                                            <hr/>
                                            <h4>Controlled Substances & Alcohol</h4>
                                            <h5>(Testing Policy)</h5>
                                            <Button
                                                variant="contained"
                                                className="docCardBtn"
                                                onClick={()=>handleDownload('substances-alcohol-policy')}
                                                disabled={isLoading}>
                                                <span className="drugFreeList__btnIcon"/>
                                                download
                                            </Button>
                                        </div>

                                        <div className="docCard RegDoc">
                                            <img className="docCardIcon" src={RegDoc} alt="NonRegDoc"/>
                                            <h2>Part 2</h2>
                                            <h3>DOT/ FMCSA Document</h3>
                                            <hr/>
                                            <h4>Controlled Substances & Alcohol</h4>
                                            <h5>(Testing 10-Point Summary)</h5>
                                            <Button
                                                variant="contained"
                                                className="docCardBtn"
                                                onClick={()=>handleDownload('substances-alcohol-summary')}
                                                disabled={isLoading}>
                                                <span className="drugFreeList__btnIcon"/>
                                                download
                                            </Button>
                                        </div>

                                        <div className="docCard RegDoc">
                                            <img className="docCardIcon" src={RegDoc} alt="NonRegDoc"/>
                                            <h2>Part 3</h2>
                                            <h3>DOT/ FMCSA Document</h3>
                                            <hr/>
                                            <h4>Glossary of Terms</h4>
                                            <h5>&nbsp;</h5>
                                            <Button
                                                variant="contained"
                                                className="docCardBtn"
                                                onClick={()=>handleDownload('glossary-dot-fmcsa')}
                                                disabled={isLoading}>
                                                <span className="drugFreeList__btnIcon"/>
                                                download
                                            </Button>
                                        </div>

                                    </div>
                                </div>
                            ) : null}

                            <div className="docsWrapper">
                                <div className="docsHeader Res">Additional Resources</div>
                                <div className="docCardsGroup">

                                    <div className="docCard Res">
                                        <img className="docCardIcon" src={Library} alt="Copy"/>
                                        <h2>Library<br/><br/></h2>
                                        <h3>Articles & Stories<br/><br/></h3>
                                        <hr/>
                                        <Button
                                            variant="contained"
                                            className="docCardBtn"
                                            onClick={() => navigate('/library')}
                                            disabled={isLoading}>
                                            EXPLORE THE LIBRARY
                                        </Button>
                                    </div>

                                    <div className="docCard Res">
                                        <img className="docCardIcon" src={Library} alt="Copy"/>
                                        <h2>Federal & State Resources</h2>
                                        <h3>Additional Drug & Alcohol Resources</h3>
                                        <hr/>
                                        <Button
                                            variant="contained"
                                            className="docCardBtn"
                                            onClick={() => navigate('/library/federal-and-state-resources')}
                                            disabled={isLoading}>
                                            EXPLORE THESE RESOURCES
                                        </Button>
                                    </div>

                                    <div className="docCard Res">
                                        <img className="docCardIcon" src={Newsletter} alt="Copy"/>
                                        <h2>News Blast<br/><br/></h2>
                                        <h3>Follow Our Exciting News!<br/><br/></h3>
                                        <hr/>
                                        <a
                                            href={"https://drugfreeguru.com/blog/"}
                                            className="docCardBtn"
                                            target={'_blank'}
                                            rel={'noreferrer'}
                                        >
                                            EXPLORE OUR NEWS BLASTS
                                        </a>
                                        {/*<Button*/}
                                        {/*    variant="contained"*/}
                                        {/*    className="docCardBtn"*/}
                                        {/*    onClick={()=>{navigate("/")}}*/}
                                        {/*    disabled={false}>*/}
                                        {/*    EXPLORE OUR NEWS BLASTS*/}
                                        {/*</Button>*/}
                                    </div>

                                    <div className="docCard Res">
                                        <img className="docCardIcon" src={Affiliate} alt="Copy"/>
                                        <h2>Affiliate Partners<br/><br/></h2>
                                        <h3>Affiliate Specialty Services<br/><br/></h3>
                                        <hr/>
                                        <Button
                                            variant="contained"
                                            className="docCardBtn"
                                            onClick={()=>{navigate("/affiliates")}}
                                            disabled={false}>
                                            GET MY AFFILIATE CODE
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </>
                    ) : null}
                </div>
            </div>
            <div className="Affiliate">
                <img className="AffiliateImg" src={AffiliateImg} alt={'AffiliateImg'}/>
                <Button
                    onClick={() => {navigate("/affiliates")}}
                    variant="contained"
                    className="docCardBtn btnAffiliate"
                >
                    RECEIVE MY AFFILIATE CODE AND TAKE ADVANTAGE OF PREFERRED PRICING
                </Button>
            </div >

            <FooterView/>
        </div>
    );
};

export default MyDFG;
