import React, {FC} from 'react';
import CategoryItem from "./CategoryItem";
import '../Library.scss'

interface Props {
    items: {
        title: string,
        alias: string
    }[];
}

const RootCategory: FC<Props> = ({items}) => {
    return (
        <>
            <div className="pageHeader">
                <div className="pageHeader__rowTitle">
                    <h2 className="pageHeader__title">Drug Free Guru Library</h2>
                </div>
            </div>

            <div className="LibraryDescription">
                <h3>Disclaimer</h3>
                <div className="LibraryDescriptionText">
                    <p>
                        This Library provides information, statistics and studies about workplace substance abuse and related topics for educational purposes and should not be relied upon as advice. The information provided in this Library does not and is not intended to constitute advise nor convey the opinions of Drug Free Guru, Inc.
                    </p>
                    <br/>
                    <p>
                        Information in this Library may not encompass the more up-to-date information. The Library may contain links to other third-party websites.  Such links are only for the convenience of the reader.  Drug Free Guru, Inc. does not recommend or endorse the contents of the third-party sites.
                    </p>
                </div>
            </div>

            <div className={'LibraryCategories'}>
                {(items && items.length > 0) && items.map((item:any, key: number) => (
                    <CategoryItem key={key} item={item}/>
                ))}
            </div>

        </>
    );
};

export default RootCategory;
