import React, {FC, useState} from 'react';
import {Controller} from 'react-hook-form';
import {FormHelperText, Autocomplete as MUIAutocomplete, TextField} from '@mui/material';
import { createFilterOptions } from '@mui/material/Autocomplete';
import FormControl from '@mui/material/FormControl';

import './Autocomplete.scss'

interface Props {
    id?: string;
    name?: string;
    title: string;
    options?: Array<{ value: string, label: string }>;
    control?: any;
    selectOne?: string;
    placeholder?: string;
    rules?: Record<string, unknown>;
    showErrorMessage?: boolean;
    isMatchFromStart?: boolean;
}

const Autocomplete: FC<Props> = ({
       id,
       name,
       title,
       options = [{value: '', label: 'Select One'}],
       control,
       placeholder = 'Select One',
       rules = {},
       showErrorMessage = true,
       isMatchFromStart = false,
    }) => {

    const autocompleteOption = options.map((item: any) => ({
        id: item.value,
        label: item.label,
    }));

    const getValue = (value: any) => {
        return autocompleteOption.find((item: any) => item.id == value)?.label || '';
    }

    const filterOptions = createFilterOptions({
        matchFrom: isMatchFromStart ? 'start' : 'any',
        stringify: (option: any) => option.label,
    });

    return (
        <div>
            <Controller
                name={name || ''}
                control={control}
                rules={rules}
                render={({field: {onChange, value}, fieldState: {error}}) =>
                    <FormControl className="selectField">
                        <div className="selectField__title">{title}</div>
                        <MUIAutocomplete
                            id={id}
                            className={`selectField__select ${error ? 'error' : ''}`}
                            filterOptions={filterOptions}
                            value={getValue(value)}
                            options={autocompleteOption}
                            renderOption={(props, option) => {
                                return (
                                    <li  {...props} key={option.id}>
                                        <span className="selectField__item">{option.label}</span>
                                    </li>
                                );
                            }}
                            renderInput={(params) => <TextField {...params} placeholder={placeholder} />}
                            onChange={(e, data) => onChange(data?.id || '0')}
                        />
                        {showErrorMessage && !!error && (
                            <FormHelperText error id={name}>
                                {`${error.message}`}
                            </FormHelperText>
                        )}
                    </FormControl>
                }
            />
        </div>
    );
};

export default Autocomplete;
