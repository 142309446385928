import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import api from '../../service/api';
import {Customer} from "../../constants";

export const fetchCustomerSignup = createAsyncThunk('customer/signup', async (params: any, {
    rejectWithValue,
    dispatch
}) => {
    const response = await api.post('customer/signup', convertDataToServer(params));
    return response.data?.resource;
});

export const fetchCustomerPaymentConfirm = createAsyncThunk('customer/confirm-payment', async (params: any, {
    rejectWithValue,
    dispatch
}) => {
    const response = await api.post('customer/confirm-payment', params);
    return response.data?.resource;
});

export const fetchMyDfg = createAsyncThunk('customer/my-dfg', async () => {
    const response = await api.get('customer/my-dfg');
    return response.data.resource;
});

export const sendQuestion = createAsyncThunk('customer/send-question', async (params: any) => {
    const response = await api.post('customer/send-question', params);
    return response.data?.resource;
});

export const sendAffiliateRequest = createAsyncThunk('customer/send-affiliate-request', async (params: any) => {
    const response = await api.post('customer/send-affiliate-request', params);
    return response.data?.resource;
});

interface CustomerState {
    customer: object | null;
    order: any | null;
    docs: Array<any>;
    order_id: string;
    client_secret: string;
    isLoading: boolean;
    isQuestionLoading: boolean;
    isAffiliateRequestLoading: boolean;
}

const initialState: CustomerState = {
    customer: null,
    order: null,
    docs: [],
    order_id: '',
    client_secret: '',
    isLoading: false,
    isQuestionLoading: false,
    isAffiliateRequestLoading: false,
};

export const customerSlice = createSlice({
    name: 'customer',
    initialState,
    reducers: {
        setCustomer: (state, action) => {
            state.customer = action.payload;
        },
    },
    extraReducers: builder => {
        builder
            .addCase(fetchCustomerSignup.pending, state => {
                state.isLoading = true;
            })
            .addCase(fetchCustomerSignup.fulfilled, (state: any, action: any) => {
                state.isLoading = false;
                if (action.payload?.client_secret && action.payload?.order_id) {
                    state.order_id = action.payload?.order_id || '';
                    state.client_secret = action.payload?.client_secret || '';
                } else {
                    state.customer = action.payload.customer;
                    state.order = action.payload.order;
                }
            })
            .addCase(fetchCustomerSignup.rejected, state => {
                state.isLoading = false;
            })
            .addCase(fetchCustomerPaymentConfirm.pending, state => {
                state.isLoading = true;
            })
            .addCase(fetchCustomerPaymentConfirm.fulfilled, (state: any, action: any) => {
                state.customer = action.payload.customer;
                state.order = action.payload.order;
                state.isLoading = false;
            })
            .addCase(fetchCustomerPaymentConfirm.rejected, state => {
                state.isLoading = false;
            })
            .addCase(fetchMyDfg.pending, state => {
                state.isLoading = true;
            })
            .addCase(fetchMyDfg.fulfilled, (state: any, action: any) => {
                state.order = action.payload.order;
                state.docs = action.payload.package?.docs || [];
                state.isLoading = false;
            })
            .addCase(fetchMyDfg.rejected, state => {
                state.isLoading = false;
            })
            .addCase(sendQuestion.pending, state => {
                state.isQuestionLoading = true;
            })
            .addCase(sendQuestion.fulfilled, (state: any, action: any) => {
                state.isQuestionLoading = false;
            })
            .addCase(sendQuestion.rejected, state => {
                state.isQuestionLoading = false;
            })
            .addCase(sendAffiliateRequest.pending, state => {
                state.isAffiliateRequestLoading = true;
            })
            .addCase(sendAffiliateRequest.fulfilled, (state: any, action: any) => {
                state.isAffiliateRequestLoading = false;
            })
            .addCase(sendAffiliateRequest.rejected, state => {
                state.isAffiliateRequestLoading = false;
            })
            .addDefaultCase(() => {
            });
    },
});

const convertDataToServer = (data: any) => {
    const ret: Customer = {
        stripe_pm: data.stripe_pm,
        package: data.package,
        affiliate_code: data.affiliate_code,
        user: {
            email: data.email,
        },
        profile: {
            first_name: data.first_name,
            last_name: data.last_name,
            company: data.company,
            referred_type: data.referred_type,
            referred_value: data.referred_value,
        },
        address: {
            state_id: data.state_id,
            address: data.address,
            address2: data.address2,
            city: data.city,
            phone: data.phone,
            zip: data.zip,
        },
    };

    return ret;
}

export const {setCustomer} = customerSlice.actions;

export default customerSlice.reducer;
