import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import api from '../../service/api';

export const fetchCategories = createAsyncThunk('library/categories', async (alias: string) => {
    const response = await api.get(`customer/library/categories${alias ? '/'+alias : ''}`);
    return response.data.resource;
});

export const fetchPages = createAsyncThunk('library/pages', async (alias: string) => {
    const response = await api.get(`customer/library/pages${alias ? '/'+alias : ''}`);
    return response.data.resource;
});

export const fetchPage = createAsyncThunk('library/page', async (alias: string) => {
    const response = await api.get(`customer/library/page/${alias}`);
    return response.data.resource;
});

interface LibraryState {
    categories: Array<any>;
    category: any;
    pages: Array<any>;
    page: any;
    isLoading: boolean;
}

const initialState: LibraryState = {
    categories: [],
    category: null,
    pages: [],
    page: null,
    isLoading: false,
};

export const librarySlice = createSlice({
    name: 'library',
    initialState,
    reducers: {
        resetCategories: (state, action) => {
            state.categories = [];
        },
        resetPages: (state, action) => {
            state.pages = [];
        },
    },
    extraReducers: builder => {
        builder
            .addCase(fetchCategories.pending, state => {
                state.isLoading = true;
            })
            .addCase(fetchCategories.fulfilled, (state: any, action: any) => {
                state.categories = action.payload.categories;
                state.category = action.payload.category;
                state.isLoading = false;
            })
            .addCase(fetchCategories.rejected, state => {
                state.isLoading = false;
            })
            .addCase(fetchPages.pending, state => {
                state.isLoading = true;
            })
            .addCase(fetchPages.fulfilled, (state: any, action: any) => {
                state.pages = action.payload;
                state.isLoading = false;
            })
            .addCase(fetchPages.rejected, state => {
                state.isLoading = false;
            })
            .addCase(fetchPage.pending, state => {
                state.isLoading = true;
            })
            .addCase(fetchPage.fulfilled, (state: any, action: any) => {
                state.page = action.payload;
                state.isLoading = false;
            })
            .addCase(fetchPage.rejected, state => {
                state.isLoading = false;
            })
            .addDefaultCase(state => {
                state.isLoading = false;
            });
    },
});

export const {resetCategories, resetPages} = librarySlice.actions;

export default librarySlice.reducer;
