import React, {FC, useState} from 'react';
import { Controller } from 'react-hook-form';
import {Input as MUIInput} from '@mui/material';
import FormControl from '@mui/material/FormControl';
import {FormHelperText} from "@mui/material";
import InputMask from 'react-input-mask';
import { NumericFormat, PatternFormat } from 'react-number-format';
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import './Input.scss'

interface Props {
    id?: string;
    name?: string;
    title?: string;
    placeholder?: string;
    control?: any;
    rules?: Record<string, unknown>;
    type?: string;
    inputProps?: Record<string, unknown>;
    showErrorMessage?: boolean;
    maxValue?: number;
    decimalScale?: number;
    prefix?: string;
    suffix?: string;
}

const Input: FC<Props> = ({
        id,
        name,
        title,
        placeholder,
        control,
        rules = {},
        type= 'input',
        inputProps,
        showErrorMessage = true,
        maxValue = 1000,
        decimalScale = 2,
        prefix = '$ ',
        suffix,
    }) => {

    const [showPassword, setShowPassword] = useState(false);

    return (
        <div className="inputContainer">
            <Controller
                name={name || ''}
                control={control}
                rules={rules}
                render={({field: { onChange, value }, fieldState: {error, isDirty}})=>
                    <FormControl className="inputField">
                        <div className="inputField__title">{title}</div>
                        {(() => {
                            if (type === "input") {
                                return (
                                    <MUIInput
                                        className={`inputField__input ${error ? 'error' : ''}`}
                                        placeholder={placeholder}
                                        id={id}
                                        name={name}
                                        value={value}
                                        onChange={onChange}
                                        error={!!error}
                                        inputProps={inputProps}
                                    />
                                )
                            } else if (type === "password") {
                                return (
                                    <MUIInput
                                        className={`inputField__input -password ${error ? 'error' : ''}`}
                                        // type={'password'}
                                        type={showPassword ? 'text' : 'password'}
                                        id={id}
                                        name={name}
                                        value={value}
                                        onChange={onChange}
                                        autoComplete="new-password"
                                        placeholder={placeholder}
                                        error={!!error}
                                        endAdornment={
                                            <InputAdornment
                                                position="end"
                                                className="password-eye"
                                                onClick={() => setShowPassword(!showPassword)}
                                            >
                                                {showPassword ? (
                                                    <Visibility color="primary" />
                                                ) : (
                                                    <VisibilityOff color="primary" />
                                                )}
                                            </InputAdornment>
                                        }
                                    />
                                )
                            } else if (type === "phone2") {
                                return (
                                    <InputMask
                                        className={`inputField__input ${error ? 'error' : ''}`}
                                        mask='(+1) 999 999 9999'
                                        value={value}
                                        id={id}
                                        onChange={onChange}
                                        placeholder={placeholder}
                                    />
                                )
                            } else if (type === "phone") {
                                return (
                                    <PatternFormat
                                        className={`inputField__input ${error ? 'error' : ''}`}
                                        id={id}
                                        value={value}
                                        onChange={onChange}
                                        customInput={MUIInput}
                                        format="+1 (###) ### ####"
                                        // allowEmptyFormatting
                                        mask="_"
                                        placeholder={placeholder}
                                    />
                                )
                            } else if (type === "currency") {
                                return (
                                    <NumericFormat
                                        className={`inputField__input ${error ? 'error' : ''}`}
                                        customInput={MUIInput}
                                        inputProps={inputProps}
                                        value={value}
                                        placeholder={placeholder}
                                        decimalScale={decimalScale}
                                        thousandSeparator={','}
                                        allowLeadingZeros={false}
                                        prefix={prefix}
                                        suffix={suffix}
                                        isAllowed={(values) => {
                                            const floatValue = values?.floatValue || 0;
                                            return floatValue >= 0 && floatValue <= maxValue;
                                        }}
                                        onValueChange={(values) => {
                                            onChange(values?.floatValue || 0);
                                        }}
                                    />
                                )
                            } else if (type === "zip") {
                                return (
                                    <NumericFormat
                                        id={id}
                                        className={`inputField__input ${error ? 'error' : ''}`}
                                        customInput={MUIInput}
                                        inputProps={inputProps}
                                        value={value}
                                        placeholder={placeholder}
                                        onChange={onChange}
                                        decimalScale={0}
                                        allowLeadingZeros={true}
                                        isAllowed={(values) => {
                                            const floatValue = values?.floatValue || 0;
                                            return floatValue >= 0 && floatValue <= 999999;
                                        }}
                                    />
                                )
                            } else {
                                return (
                                    <></>
                                )
                            }
                        })()}
                        {showErrorMessage && !!error && (
                            <FormHelperText error id={name}>
                                {`${error.message}`}
                            </FormHelperText>
                        )}
                    </FormControl>
                }
            />
        </div>
    );
};

export default Input;
