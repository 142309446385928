import React, {FC} from 'react';
import { useAppSelector} from "../../../hooks/global";
import HeaderView from '../../common/Header/HeaderView'
import FooterView from '../../common/Footer/FooterView'
import TopBanner from '../../common/TopBanner/TopBanner'
import DSG from '../../../assets/images/affiliates/dsg.png';
import Currency from '../../../assets/images/affiliates/currency.png';
import Ora from '../../../assets/images/affiliates/ora.png';
import Quantum from '../../../assets/images/affiliates/quantum.png';
import Current from '../../../assets/images/affiliates/current.png';
import AffiliateCard from "./AffiliateCard";
import './Affiliates.scss'

const Affiliates: FC = () => {

    const {user} = useAppSelector(state => state.auth);
    const code = user?.profile?.code || "";

    return (
        <div className="contentWrapper">
            <HeaderView/>
            <div className="pageContainer">
                <TopBanner title={'AFFILIATE PARTNERS'} page={'affiliates'}/>
                <div className="pageContainer__view">
                    <div className="pageHeader">
                        <div className="pageHeader__rowTitle">
                            <h2 className="pageHeader__title">Affiliate Partners</h2>
                        </div>
                    </div>
                    <div className="desc">
                        <div className="descText">
                            DFG’s digital platform provides access to “value-added” services at preferred pricing.  These services are integral parts of managing a comprehensive, defensible substance abuse prevention program.  Once a DFG subscriber has completed their documents, they can then select any or all of our “best of breed” Affiliates who can provide tailored solutions to the subscribers needs.
                        </div>
                    </div>
                    <div className={"affiliates"}>

                        <AffiliateCard code={code} alias={'dfg'} title={'DRUG-FREE SOLUTIONS GROUP, LLC'} image={DSG} odd={false}>
                            <div>
                                <div className={'affiliatesCardDescHeader'}>
                                    DRUG-FREE SOLUTIONS GROUP, LLC
                                </div>
                                <div className={'affiliatesCardDescText'}>
                                    <p>
                                        Drug-Free Solutions Group, LLC (“DSG”) was founded by Christine Clearwater in 2000 and also established Drug Free Guru (“DFG”) in 2023 with the commitment to provide services that reduce the risks and losses due to the impact of substance abuse in the workplace.
                                    </p>
                                    <p>
                                        As one of the leading specialty consulting firms in the U.S., DSG has been the firm of choice for over 2,000 small and medium nationally based clients representing almost every industry.
                                    </p>
                                    <p>
                                        DSG offers a holistic approach to managing workplace substance abuse including custom policy development for non-regulated and DOT/FMCSA populations, manager training and employee education, courtesy policy reviews, as well as utilizing the combined purchasing power for all its clients to achieve cost savings for drug and alcohol testing, Employee Assistance Programs (EAPs), eLearning, and state law services.
                                    </p>
                                    <p>
                                        DSG serves as an integral resource for its clients, their managers and their human resources and safety personnel on an ongoing basis. As times change, as the industry changes, as the economy changes and as our clients change – DSG continues to be there for the client’s lasting success.
                                    </p>
                                    <p>
                                        Ms. Clearwater has authored numerous articles, been interviewed regularly by local, state, and national publications and is a prominent speaker to associations, insurance companies, and business groups.
                                    </p>
                                    <p>
                                        DSG is available to provide consulting assistance to subscribers of Drug Free Guru.
                                    </p>
                                </div>
                                <div className={'affiliatesCardDescCode'}>
                                    If interested, using your Guru code, contact:
                                </div>
                                <div className={'affiliatesCardDescContact'}>
                                    Christine Clearwater, President/Founder at:
                                </div>
                                <div className={'affiliatesCardDescContactLine'}>
                                    Email Address: <span><a href="mailto:christine@drugfreeatwork.com">christine@drugfreeatwork.com</a></span>
                                </div>
                                <div className={'affiliatesCardDescContactLine'}>
                                    Direct: <span>561-266-5111</span>
                                </div>
                            </div>
                        </AffiliateCard>

                        <AffiliateCard code={code} alias={'current'} title={'Current Consulting Group'} image={Current} odd={true}>
                            <div>
                                <div className={'affiliatesCardDescHeader'}>
                                    CURRENT
                                </div>
                                <div className={'affiliatesCardDescHeader'}>
                                    Compliance-Comprehensive State Law Review
                                </div>
                                <div className={'affiliatesCardDescText'}>
                                    <p>
                                        Understanding the laws that affect drug testing in the state or states in which your company operates can be daunting. This is where the Current Consulting Group (“CCG”) comes in. Founded by Bill Current in 1998, CCG was identified in a survey of its peers as the number one recognized brand in drug testing compliance. Through its on-line subscription database, Current Compliance, CCG offers employers access to all the pertinent state laws, information necessary for employers to help employers a compliant drug testing program. Current State Law Database is comprehensive, easy to use, and regularly updated, and includes state legal cannabis laws, workers’, and unemployment compensation regulations, and much more. All in one place and available 24/7/365.
                                    </p>
                                </div>
                               <div className={'affiliatesCardDescCode'}>
                                    If interested in these services, using your Guru code, contact:
                                </div>
                                <div className={'affiliatesCardDescContacts'}>
                                    <div className={'affiliatesCardDescContactsCol'}>
                                        <div className={'affiliatesCardDescContact'}>
                                            <b>For State Law:</b>
                                        </div>
                                        <div className={'affiliatesCardDescContact'}>
                                            Rick Tennant at
                                        </div>
                                        <div className={'affiliatesCardDescContactLine'}>
                                            Email Address:<br/><span><a href="mailto:rtennant@currentconsultinggroup.com">rtennant@currentconsultinggroup.com</a></span>
                                        </div>
                                        <div className={'affiliatesCardDescContactLine'}>
                                            Direct: <span>801-502-6608</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </AffiliateCard>

                        <AffiliateCard code={code} alias={'ora'} title={'OraSure Technologies'} image={Ora} odd={false}>
                            <div>
                                <div className={'affiliatesCardDescHeader'}>
                                    ORASURE TECHNOLOGIES
                                </div>
                                <div className={'affiliatesCardDescText'}>
                                    <p>
                                        The OraSure Technologies, Inc. (“OraSure”), founded in 1988, is family of companies delivering innovative solutions and services backed by science that our customers need to solve the world’s greatest health and wellness challenges.
                                    </p>
                                    <p>
                                        As part of these services, OraSure is a global leader in oral fluid substance abuse testing products.  Our unique assays provide accurate and easy-to-administer methods to help determine the presence or absence of drugs or alcohol in a person’s system.  Oral fluid-based testing products provide a simplified collection process, faster results, and cost savings, with minimal risk of tampering and dramatically reduced risk of adulteration.
                                    </p>
                                    <p>
                                        OraSure’s team is committed to ensuring our clients have the on-going support needed starting with the best products for their needs, through implementation, and for the on-going continuance of a successful substance abuse testing program.
                                    </p>
                                    <p>
                                        In addition, OraSure was ranked as the #1 brand name in oral fluid testing on the 2021 National Drug Testing Industry Survey.
                                    </p>
                                </div>
                                <div className={'affiliatesCardDescCode'}>
                                    If interested, using your Guru code, contact:
                                </div>
                                <div className={'affiliatesCardDescContact'}>
                                    Kati Wagner at:
                                </div>
                                <div className={'affiliatesCardDescContactLine'}>
                                    Email Address: <span><a href="mailto:kwagner@orasure.com">kwagner@orasure.com</a></span>
                                </div>
                                <div className={'affiliatesCardDescContactLine'}>
                                    Direct: <span>(484) 353-1628 or 800-869-3538 ext. 1628</span>
                                </div>
                            </div>
                        </AffiliateCard>

                        <AffiliateCard code={code} alias={'quantum'} title={'Quantum Health Solutions'} image={Quantum} odd={true}>
                            <div>
                                <div className={'affiliatesCardDescHeader'}>
                                    QUANTUM HEALTH SOLUTIONS
                                </div>
                                <div className={'affiliatesCardDescText'}>
                                    <p>
                                        Quantum Health Solutions, Inc. (“Quantum”) began in 1982 specifically to design and deliver Employee Assistance Programs (EAPs) and behavioral health care services.  Quantum has provided over 40 years of continuous service to our clients.  Our client list includes large and small companies, service, manufacturing and construction industries, non-profit entities and local governments.
                                    </p>
                                    <p>
                                        Since our charter began, we have come to know every workforce struggles with life events, personal situations and emotional complexities.  These problems can often cause personal distress, but often adversely on the employee’s job performance, productivity and safety.
                                    </p>
                                    <p>
                                        Quantum team of professionals offer services nationally.  Our system of flexible and in-the-moment programs assist companies when responding to workplace situations and employee emotional wellness.  Quantum provides a conduit of employer support to human resources and operations when managing complex conditions and policy problems solving.
                                    </p>
                                    </div>
                                <div className={'affiliatesCardDescCode'}>
                                    If interested, using your Guru code, contact:
                                </div>

                                <div className={'affiliatesCardDescContacts'}>
                                    <div className={'affiliatesCardDescContactsCol'}>
                                        <div className={'affiliatesCardDescContact'}>
                                            Leslie Lippert at:
                                        </div>
                                        <div className={'affiliatesCardDescContactLine'}>
                                            Email Address: <span><a href="mailto:llippert@accessqhs.com">llippert@accessqhs.com</a></span>
                                        </div>
                                        <div className={'affiliatesCardDescContactLine'}>
                                            Direct: <span>386-597-0083 ext. 105</span>
                                        </div>
                                    </div>

                                    <div className={'affiliatesCardDescContact'}>
                                        or
                                    </div>

                                    <div className={'affiliatesCardDescContactsCol'}>
                                        <div className={'affiliatesCardDescContact'}>
                                            John Althouse at:
                                        </div>
                                        <div className={'affiliatesCardDescContactLine'}>
                                            Email Address: <span><a href="mailto:jalthouse@accessqhs.com">jalthouse@accessqhs.com</a></span>
                                        </div>
                                        <div className={'affiliatesCardDescContactLine'}>
                                            Direct: <span>386-597-0083 ext. 122</span>
                                        </div>
                                    </div>

                                </div>



                            </div>
                        </AffiliateCard>

                        <AffiliateCard code={code} alias={'currency'} title={'CURRENCY'} image={Currency} odd={false}>
                            <div>
                                <div className={'affiliatesCardDescHeader'}>
                                    CURRENCY
                                </div>
                                <div className={'affiliatesCardDescHeader'}>
                                    eLearning Supervisor and Manager Training Platform
                                </div>
                                <div className={'affiliatesCardDescText'}>
                                    <p>
                                        Current Consulting Group (CCG) offers on-line, state-of-the-art training courses for DOT-required and non-DOT supervisor training and employee drug education. CCG also offers in-person training services. This allows employers the flexibility to meet their organization’s needs in the most efficient and cost-effective ways. In-person training is more engaging, however the logistics of getting everyone together is sometimes difficult if not impossible under some circumstances and budgets. Online training can be done anywhere and anytime. Our platform also allows for the learner to start training and pause training on their schedule, meaning important things won’t be missed and required training will still get done.
                                    </p>
                                    <p>
                                        All courses, whether online or in person, include videos, quizzes, forms, and certificates CEU’s for SHRM and HRCI are also available.
                                    </p>
                                </div>
                                <div className={'affiliatesCardDescCode'}>
                                    If interested, using your Guru code, contact:
                                </div>
                                <div className={'affiliatesCardDescContactsCol'}>
                                    <div className={'affiliatesCardDescContact'}>
                                        <b>For eLearning:</b>
                                    </div>
                                    <div className={'affiliatesCardDescContact'}>
                                        Jeremy Endres at
                                    </div>
                                    <div className={'affiliatesCardDescContactLine'}>
                                        Email Address:<br/><span><a href="mailto:jendres@currentconsultinggroup.com">jendres@currentconsultinggroup.com</a></span>
                                    </div>
                                    <div className={'affiliatesCardDescContactLine'}>
                                        Direct: <span>954-944-0400</span>
                                    </div>
                                </div>
                            </div>
                        </AffiliateCard>

                    </div>
                </div>
            </div>
            <FooterView/>
        </div>
    );
};

export default Affiliates;
