import React, {FC} from 'react';
import Button from '@mui/material/Button';
import './IntroPage.scss';

type Props = {
    order: any,
    handleStart: () => void,
};

const IntroPage: FC<Props> = ({order, handleStart}) => {
    return (
        <div className={'introWrapper'}>

            {(order?.package === 'nonreg-docs') ? (
                <div className={'introContent'}>
                    <br/>
                    <h3>WHAT TO EXPECT WHEN DEVELOPING YOUR DRUG-FREE WORKPLACE DOCUMENTS</h3>
                    <br/>
                    <br/>
                    <p>
                        <b>IMPORTANT OVERVIEW BEFORE STARTING</b>
                    </p>
                    <p>
                        Drug-Free Guru was designed by our team to enable you to create customized Drug-Free Workplace documents based on your operations, management style, culture, and exposure to risk and losses. It is structured to provide you with comprehensive content and information that will lead you to making sound decisions in the future.
                    </p>
                    <p>
                        To build your non-regulated documents, there are forty-one (41) questions to answer. Throughout the document development process, you will find Guru Pointers which will take you to the Guru Guidance section. The Guru Guidance section has been carefully crafted providing thoughts, facts, perspectives, explanations, pros and cons to consider when making your decisions.
                    </p>
                    <p>
                        <b>Please note:</b> it is important to include the proper article such as “a” or “an” or “the” before adding the job titles.  Examples are “a” Safety Manager or “the” Human Resources Manager or “a” member of the Executive Team. This will ensure that these sentences will flow properly. If you do forget, you can add the article in later.
                    </p>
                    <p>
                        For the non-regulated documents you will receive a Policy, Policy Summary, Glossary, and Forms. All documents can be stored for future reference, editing, as well as printed out.
                    </p>
                    <p>
                        These customized documents will provide a clear, effective road map needed for most any situation that may arise related to workplace substance use and abuse.
                    </p>
                    <p>
                        After receiving your documents, you can get familiar with Guru’s Affiliate Partners to determine if there are products or services that will support your Non-regulated Program. As you know a policy is not a program, so Guru offers access to the Affiliates who are best-of-breed and provide discounted rates to Guru subscribers that can transform your policy into a program. You can always return at a later date to edit or print your documents, reach out to any of the Affiliate Partners or if you are not ready to decide and delve into the extensive Library and Federal and State Resource Center.
                    </p>
                    <br/>
                    <p>
                        <b><i>THREE ESSENTIALS:</i></b>
                    </p>
                    <br/>

                    <b>
                        <i>
                            <ul>
                                <li>
                                    Consider having key decision-makers as part of the policy development process. By doing so, it will generate valuable discussion from different perspectives as you go through the process, such as Executive Team Members, Human Resources, Safety and Operations Manager.
                                </li>
                                <li>
                                    Read all the Guru Guidance sections <u>before</u> providing your answers.
                                </li>
                                <li>
                                    Complete the Drug-Free Guru questions in one sitting to maintain continuity of your thought process and anticipate approximately 2 ½ hours to complete.
                                </li>
                            </ul>
                        </i>
                    </b>

                    <br/>
                    <br/>
                    <div className={'introContent__center'}>
                        <p className={'getStarted'}><i>So, let’s get started. Plan on learning a lot and having fun in the process!</i></p>
                    </div>
                    <br/>
                </div>
            ): null}

            {(order?.package === 'dot-fmcsa-reg-docs') ? (
                <div className={'introContent'}>
                    <br/>
                    <h3>WHAT TO EXPECT WHEN DEVELOPING YOUR DRUG-FREE WORKPLACE DOCUMENTS</h3>
                    <br/>
                    <br/>
                    <p>
                        <b>IMPORTANT OVERVIEW BEFORE STARTING</b>
                    </p>
                    <p>
                        Drug-Free Guru was designed by our team to enable you to create customized Drug-Free Workplace documents based on your operations, management style, culture, and exposure to risk and losses. It is structured to provide you with comprehensive content and information that will lead you to making sound decisions in the future.
                    </p>
                    <p>
                        To build your DOT/FMCSA documents, there are eleven (11) questions to answer. Throughout the document development process, you will find Guru Pointers which will take you to the Guru Guidance section. The Guru Guidance section has been carefully crafted providing thoughts, facts, perspectives, explanations, pros and cons to consider when making your decisions.
                    </p>
                    <p>
                        <b>Please note:</b> it is important to include the proper article such as “a” or “an” or “the” before adding the job titles.  Examples are “a” Safety Manager or “the” Human Resources Manager or “a” member of the Executive Team. This will ensure that these sentences will flow properly. If you do forget, you can add the article in later.
                    </p>
                    <p>
                        For the DOT/FMCSA documents, you will receive a DOT/FMCSA Controlled Substances and Alcohol Testing Policy, 10-Point Policy Summary for drivers, and Glossary. All documents can be stored for future reference, editing, as well as printed out.
                    </p>
                    <p>
                        These customized documents will provide a clear, effective road map needed for most any situation that may arise related to workplace substance use and abuse.
                    </p>
                    <p>
                        After receiving your documents, you can get familiar with Guru’s Affiliate Partners to determine if there are products or services that will support your DOT/FMCSA Program. As you know a policy is not a program, so Guru offers access to the Affiliates who are best-of-breed and provide discounted rates to Guru subscribers that can transform your policy into a program. You can always return at a later date to edit or print your documents, reach out to any of the Affiliate Partners or if you are not ready to decide and delve into the extensive Library and Federal and State Resource Center.
                    </p>
                    <br/>
                    <p>
                        <b><i>THREE ESSENTIALS:</i></b>
                    </p>
                    <br/>

                    <b>
                        <i>
                            <ul>
                                <li>
                                    Consider having key decision-makers as part of the policy development process. By doing so, it will generate valuable discussion from different perspectives as you go through the process, such as Executive Team Members, Human Resources, Safety and Operations Manager.
                                </li>
                                <li>
                                    Read all the Guru Guidance sections <u>before</u> providing your answers.
                                </li>
                                <li>
                                    Complete the Drug-Free Guru questions in one sitting to maintain continuity of your thought process and anticipate approximately 2 ½ hours to complete.
                                </li>
                            </ul>
                        </i>
                    </b>

                    <br/>
                    <br/>
                    <div className={'introContent__center'}>
                        <p className={'getStarted'}><i>So, let’s get started. Plan on learning a lot and having fun in the process!</i></p>
                    </div>
                    <br/>
                </div>
            ): null}

            {(order?.package === 'nonreg-dot-fmcsa-reg-docs') ? (
                <div className={'introContent'}>
                    <br/>
                    <h3>WHAT TO EXPECT WHEN DEVELOPING YOUR DRUG-FREE WORKPLACE DOCUMENTS</h3>
                    <br/>
                    <br/>
                    <p>
                        <b>IMPORTANT OVERVIEW BEFORE STARTING</b>
                    </p>
                    <p>
                        Drug-Free Guru was designed by our team to enable you to create customized Drug-Free Workplace documents based on your operations, management style, culture, and exposure to risk and losses. It is structured to provide you with comprehensive content and information that will lead you to making sound decisions in the future.
                    </p>
                    <p>
                        To build your non-regulated and DOT/FMCSA documents, there are forty-nine (49) questions to answer. Throughout the document development process, you will find Guru Pointers which will take you to the Guru Guidance section. The Guru Guidance section has been carefully crafted providing thoughts, facts, perspectives, explanations, pros and cons to consider when making your decisions.
                    </p>
                    <p>
                        <b>Please note:</b> it is important to include the proper article such as “a” or “an” or “the” before adding the job titles.  Examples are “a” Safety Manager or “the” Human Resources Manager or “a” member of the Executive Team. This will ensure that these sentences will flow properly. If you do forget, you can add the article in later.
                    </p>
                    <p>
                        For the non-regulated documents you will receive a Policy, Policy Summary, Glossary, and Forms. If you are also subscribing to the DOT/FMCSA documents, you will receive a DOT/FMCSA Controlled Substances and Alcohol Testing Policy, 10-Point Policy Summary for drivers, and Glossary. All documents can be stored for future reference, editing, as well as printed out.
                    </p>
                    <p>
                        These customized documents will provide a clear, effective road map needed for most any situation that may arise related to workplace substance use and abuse.
                    </p>
                    <p>
                        After receiving your documents, you can get familiar with Guru’s Affiliate Partners to determine if there are products or services that will support your Non-regulated and/or DOT/FMCSA Program. As you know a policy is not a program, so Guru offers access to the Affiliates who are best-of-breed and provide discounted rates to Guru subscribers that can transform your policy into a program. You can always return at a later date to edit or print your documents, reach out to any of the Affiliate Partners or if you are not ready to decide and delve into the extensive Library and Federal and State Resource Center.
                    </p>
                    <br/>
                    <p>
                        <b><i>THREE ESSENTIALS:</i></b>
                    </p>
                    <br/>

                    <b>
                        <i>
                            <ul>
                                <li>
                                    Consider having key decision-makers as part of the policy development process. By doing so, it will generate valuable discussion from different perspectives as you go through the process, such as Executive Team Members, Human Resources, Safety and Operations Manager.
                                </li>
                                <li>
                                    Read all the Guru Guidance sections <u>before</u> providing your answers.
                                </li>
                                <li>
                                    Complete the Drug-Free Guru questions in one sitting to maintain continuity of your thought process and anticipate approximately 2 ½ hours to complete.
                                </li>
                            </ul>
                        </i>
                    </b>

                    <br/>
                    <br/>
                    <div className={'introContent__center'}>
                        <p className={'getStarted'}><i>So, let’s get started. Plan on learning a lot and having fun in the process!</i></p>
                    </div>
                    <br/>
                </div>
            ): null}

            {order ? (
                <Button
                    onClick={handleStart}
                    variant="contained"
                    className="stepContent__btn simpleBtn -border"
                >
                    Get Started Now!
                </Button>
            ) : null}

        </div>
    );
};

export default IntroPage;
