import React, {FC, useEffect, useState} from 'react';
import Button from "@mui/material/Button";
import {ROUTES} from "../../../../router/routes";
import '../Library.scss'

interface Props {
    item: any;
}

const CategoryItem: FC<Props> = ({item}) => {

    return (
        <div className="CategoryCard">
            <h2>{item.title}</h2>
            <hr/>
            <h4>{item.pages_count} Articles</h4>
            <a className="CategoryCardLink" href={`${ROUTES.LIBRARY}/${item.alias}`}>
                <Button
                    variant="contained"
                    className="CategoryCardLinkBtn"
                    onClick={()=>{}}
                >
                    Learn More
                </Button>
            </a>
        </div>
    );
};

export default CategoryItem;
