import React, {FC, useState, useEffect, useRef} from 'react';
import {useForm, useWatch} from "react-hook-form";
import {useSearchParams, useNavigate} from 'react-router-dom';
import {useAppDispatch, useAppSelector} from '../../../hooks/global';
import {fetchPrices, fetchStates} from "../../../store/general/generalSlice";
import {fetchCustomerSignup, fetchCustomerPaymentConfirm} from "../../../store/customer/customerSlice";
import HeaderView from '../../common/Header/HeaderView'
import FooterView from '../../common/Footer/FooterView'
import TopBanner from '../../common/TopBanner/TopBanner'
import {Input, Select, Autocomplete} from "../../controls";
import Spinner from "../../common/Spinner/Spinner";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import StripeForm from "../../common/StripeForm/StripeForm";
import NonRegDoc from '../../../assets/images/icons/doc_non_reg.svg';
import RegDoc from '../../../assets/images/icons/doc_reg.svg';
import Library from '../../../assets/images/icons/library.svg';
import Back from '../../../assets/images/icons/back.svg';
import './SelectPackage.scss'

const referredOptions = [
  // {value: 'affiliate', label: 'Affiliate'},
  {value: 'search', label: 'Google / Search Engine'},
  {value: 'insurance', label: 'Insurance Provider'},
  {value: 'association', label: 'Group / Association'},
  {value: 'other', label: 'Other'},
]

const SelectPackage: FC = () => {
  const navigation = useNavigate();
  const myRef = useRef<null | HTMLDivElement>(null);
  const [searchParams] = useSearchParams();
  const affiliateCode = searchParams.get('affiliate');
  const packageParam = searchParams.get('selected');
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const {prices, states, publishable_key, affiliate_id} = useAppSelector(state => state.general);
  const {client_secret, order_id, isLoading} = useAppSelector(state => state.customer);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);
  const [selectedPackage, setSelectedPackage] = useState('nonreg-docs');
  const [statesOptions, setStatesOptions] = useState([{value: '', label: ''}]);
  const [stripePromise, setStripePromise] = useState<any>(null);
  const {control, handleSubmit, reset, trigger, setValue, setError, getValues, formState: {errors, isValid}} = useForm({
    mode: 'onBlur',
    defaultValues: {
      package: 'nonreg-docs',
      first_name: '',
      last_name: '',
      phone: '',
      email: '',
      company: '',
      state_id: '',
      address: '',
      address2: '',
      city: '',
      zip: '',
      affiliate_code: '',
      stripe_pm: '',
      referred_type: 'search',
      referred_value: '',
    }
  });

  const referredType = useWatch({
    control,
    name: 'referred_type',
  });

  useEffect(() => {
    if (myRef &&  myRef?.current) {
      myRef.current.scrollIntoView();
    }

    dispatch(fetchPrices({affiliate_code: affiliateCode}));
    dispatch(fetchStates());
  }, []);

  useEffect(() => {
    setStatesOptions(states.map((item) => ({
      value: item.id,
      label: item.code,
    })))
  }, [states]);

  useEffect(() => {
    setValue('affiliate_code', affiliateCode || '');
  }, [affiliateCode]);

  useEffect(() => {
    if (selectedPackage) {
      setValue('package', selectedPackage);
    }
  }, [selectedPackage]);

  useEffect(() => {
    if (packageParam === '1') {
      setSelectedPackage('nonreg-docs');
    } else if (packageParam === '2') {
      setSelectedPackage('dot-fmcsa-reg-docs');
    } else if (packageParam === '3') {
      setSelectedPackage('nonreg-dot-fmcsa-reg-docs');
    }
  }, [packageParam]);

  useEffect(() => {
    if (publishable_key) {
      setStripePromise(loadStripe(publishable_key));
    }
  }, [publishable_key]);

  useEffect(() => {
    setValue('referred_value', '');
  }, [referredType]);

  const priceHandler = (value: string) => {
    setSelectedPackage(value);
    setValue('package', value);
  };

  const handleOnSubmit = (data: any) => {
    setIsSubmitDisabled(true);
    dispatch(fetchCustomerSignup(data)).then(resp => {
      if (resp?.type === 'customer/signup/fulfilled') {
        if (resp.payload?.order) {
          navigate("/thanks", { state: {
              packageType: resp.payload.order?.package,
              price: resp.payload.order?.price,
            }});
        }
      } else if (resp?.type === 'customer/signup/rejected') {
        setIsSubmitDisabled(false);
      }
    });
  };

  const handleGeneralSubmit = (stripePayload: any) => {
    const {paymentMethod, error} = stripePayload;
    if (paymentMethod && paymentMethod.id) {
      setValue('stripe_pm', paymentMethod.id);
      handleSubmit((data) => handleOnSubmit(data))();
    }
  }

  const handle3DConfirmPayment = (data: any) => {
    const {payment_intent_id, error} = data;
    //console.log('handle3DConfirmPayment payment_intent_id error', payment_intent_id, error);
    dispatch(fetchCustomerPaymentConfirm({...data, order_id})).then(resp => {
      if (resp?.type === 'customer/confirm-payment/fulfilled') {
        const {payload: {order}} = resp;
        navigate("/thanks", { state: {
            packageType: order.package,
            price: order.price,
        }});
      } else if (resp?.type === 'customer/confirm-payment/rejected') {
        setIsSubmitDisabled(false);
      }
    });
  };

  const getReferredValueName = (): string => {
    const option = referredOptions.find(item=>(item.value === referredType));
    return option?.label || '';
  }

  return (
      <div className="contentWrapper">
        <HeaderView />
        <Spinner isLoading={isLoading}/>
        <div className="pageContainer">
          <TopBanner />
          <div className="pageContainer__view">
            <div className="pageHeader">
              <a className="pageHeader__back" href={'https://www.drugfreeguru.com'} rel={'noreferrer'}><img src={Back} alt={'#'}/>Back to the Drug Free Guru homepage</a>
              <div className="pageHeader__rowTitle">
                <h2 ref={myRef} className="pageHeader__title">Select a Drug Free Solutions Package</h2>
              </div>
            </div>
            <form className="formContainer">

              <div className="priceWrapper">
                  <div className={`priceItem NonReg ${selectedPackage==='nonreg-docs' ? '-active' : ""}`} onClick={()=>priceHandler('nonreg-docs')}>
                    <img className="docCardIcon" src={NonRegDoc} alt="NonRegDoc"/>
                    <h2>Non-Regulated</h2>
                    <h3>Documents</h3>
                    <hr/>
                    <h4>Drug-Free Workplace Policy</h4>
                    <h4>Drug-Free Workplace Policy Summary</h4>
                    <h4>Forms Section</h4>
                    <h4>Glossary of Terms</h4>
                    <h4>Resource Center (Federal & State)</h4>
                    <h4>Library (Articles, Stories, Webcasts, etc.)</h4>
                    <h4>Quarterly News Blast</h4>
                    <a className="priceItemReadMore">{selectedPackage==='nonreg-docs' ? 'Package Selected' : 'Select Package'}</a>
                    <div className="priceItemFooter">
                      <span className="priceItemPrice">{`$${prices.non_reg_price.toFixed(2)}`}</span>
                    </div>
                  </div>
                  <div className={`priceItem RegDoc ${selectedPackage==='dot-fmcsa-reg-docs' ? "-active" : ""}`} onClick={()=>priceHandler('dot-fmcsa-reg-docs')}>
                    <img className="docCardIcon" src={RegDoc} alt="NonRegDoc"/>
                    <h2>Regulated</h2>
                    <h3>DOT / FMCSA Documents</h3>
                    <hr/>
                    <h4>Controlled Substances & Alcohol</h4>
                    <h5>(Testing Policy)</h5>
                    <h4>Controlled Substances & Alcohol</h4>
                    <h5>(Testing 10-Point Summary)</h5>
                    <h4>Glossary of Terms</h4>
                    <h4>Resource Center (Federal & State)</h4>
                    <h4>Library (Articles, Stories, Webcasts, etc.)</h4>
                    <h4>Quarterly News Blast</h4>
                    <a className="priceItemReadMore">{selectedPackage==='dot-fmcsa-reg-docs' ? 'Package Selected' : 'Select Package'}</a>
                    <div className="priceItemFooter">
                      <span className="priceItemPrice">{`$${prices.dot_fmcsa_price.toFixed(2)}`}</span>
                    </div>
                  </div>
                  <div className={`priceItem AllDocs ${selectedPackage==='nonreg-dot-fmcsa-reg-docs' ? '-active' : ""}`} onClick={()=>priceHandler('nonreg-dot-fmcsa-reg-docs')}>
                    <img className="docCardIcon" src={Library} alt="NonRegDoc"/>
                    <h2>All Documents</h2>
                    <h3>Non-Reg & DOT / FMCSA</h3>
                    <hr/>
                    <h4>Drug-Free Workplace Policy</h4>
                    <h4>Drug-Free Workplace Policy Summary</h4>
                    <h4>Forms Section</h4>
                    <h4>Controlled Substances & Alcohol </h4>
                    <h5>(Testing Policy)</h5>
                    <h4>Controlled Substances & Alcohol</h4>
                    <h5>(Testing 10-Point Summary)</h5>
                    <h4>Glossary of Terms</h4>
                    <h4>Resource Center (Federal & State)</h4>
                    <h4>Library (Articles, Stories, Webcasts, etc.)</h4>
                    <h4>Quarterly News Blast</h4>
                    <a className="priceItemReadMore">{selectedPackage==='nonreg-dot-fmcsa-reg-docs' ? 'Package Selected' : 'Select Package'}</a>
                    <div className="priceItemFooter">
                      <span className="priceItemPrice">{`$${prices.non_reg_dot_fmcsa_price.toFixed(2)}`}</span>
                    </div>
                  </div>
              </div>

              <div className="InAddition">
                <p><b style={{fontFamily: 'Poppins, sans-serif', fontWeight: 700}}>In Addition:</b></p>
                <p>For an <b className={'blueColor'}>Annual Renewal</b> fee of <b className={'blueColor'}>$275</b> (after 1st Year), you have on-going access to update your policy documents, access to continually updated <b>Resources Center</b>, as well as the <b>Affiliates</b> with their <b className={'blueColor'}>Preferred Pricing</b>.</p>
              </div>

              <div className="formContainer__row -grid-2 mb-0">
                <div className="formContainer__column">
                  <h3 className="formContainer__title">Customer Details</h3>
                  <div className="formContainer__row -grid-2 mb-0">
                    <Input
                        id={'first_name'}
                        control={control}
                        name={'first_name'}
                        title={'first name*'}
                        placeholder={'Enter First Name'}
                        rules={{
                          required: "This field is required",
                        }}
                        inputProps={{maxLength: 50}}
                    />
                    <Input
                        id={'last_name'}
                        control={control}
                        name={'last_name'}
                        title={'Last name*'}
                        placeholder={'Enter Last Name'}
                        rules={{
                          required: "This field is required",
                        }}
                        inputProps={{maxLength: 50}}
                    />
                  </div>
                </div>
                <div className="formContainer__column">
                  <h3 className="formContainer__title">Contact Information</h3>
                  <div className="formContainer__row -grid-2 mb-0">
                    <Input
                        id={'phone'}
                        control={control}
                        name={'phone'}
                        title={'Phone Number*'}
                        placeholder={'Enter Phone Number'}
                        type={'phone'}
                        rules={{
                          required: "This field is required",
                        }}
                    />
                    <Input
                        id={'email'}
                        control={control}
                        name={'email'}
                        title={'Email Address*'}
                        placeholder={'Enter Email Address'}
                        rules={{
                          required: "This field is required",
                          pattern: {
                            value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                            message: "Invalid Email Address"
                          }
                        }}
                        inputProps={{maxLength: 100}}
                    />
                  </div>
                </div>
              </div>
              <h3 className="formContainer__title">Company Information</h3>
              <div className="formContainer__row -grid-4">
                <Input
                    id={'company'}
                    control={control}
                    name={'company'}
                    title={'Company Name*'}
                    placeholder={'Enter Company Name'}
                    inputProps={{maxLength: 100}}
                    rules={{
                      required: "This field is required",
                    }}
                />
                <Input
                    id={'address'}
                    control={control}
                    name={'address'}
                    title={'Address 1*'}
                    placeholder={'Enter Address 1'}
                    inputProps={{maxLength: 100}}
                    rules={{
                      required: "This field is required",
                    }}
                />
                <Input
                    id={'address2'}
                    control={control}
                    name={'address2'}
                    title={'Address 2'}
                    placeholder={'Enter Address 2'}
                    inputProps={{maxLength: 100}}
                />
              </div>
              <div className="formContainer__row -grid-4">
                <Input
                    id={'city'}
                    control={control}
                    name={'city'}
                    title={'City*'}
                    placeholder={'Enter City'}
                    inputProps={{maxLength: 50}}
                    rules={{
                      required: "This field is required",
                    }}
                />
                <Autocomplete
                    id={'state_id'}
                    control={control}
                    name={'state_id'}
                    title={'State*'}
                    options={statesOptions}
                    placeholder={'Select One'}
                    isMatchFromStart={true}
                    rules={{
                      validate: {
                        validate: (value: any) => {
                          return (!value || value === '') ? 'This field is required' : true;
                        }
                      }
                    }}
                />
                <Input
                    id={'zip'}
                    control={control}
                    name={'zip'}
                    title={'Zip Code*'}
                    type={'zip'}
                    placeholder={'Enter Zip Code'}
                    inputProps={{maxLength: 5}}
                    rules={{
                      required: "This field is required",
                    }}
                />
              </div>
              {(!affiliate_id) && (
                  <>
                    <h3 className="formContainer__title">How did you learn about Drug Free Guru?</h3>
                    <div className="formContainer__row -grid-4 alignTop">
                      <Select
                          control={control}
                          name={'referred_type'}
                          //title={'How did you learn about Drug Free Guru?*'}
                          title={''}
                          options={referredOptions}
                      />
                      {(referredType === 'association' || referredType === 'other') && (
                          <Input
                              control={control}
                              name={'referred_value'}
                              //title={`${getReferredValueName()}*`}
                              rules={{
                                required: "This field is required",
                              }}
                              inputProps={{maxLength: 50}}
                              showErrorMessage={false}
                          />
                      )}
                    </div>
                  </>
              )}
              <h3 className="formContainer__title">Credit Card Details</h3>
              <Elements stripe={stripePromise}>
                <StripeForm
                    handleGeneralSubmit={handleGeneralSubmit}
                    formTrigger={trigger}
                    on3DConfirmPayment={handle3DConfirmPayment}
                    clientSecret={client_secret}
                    isSubmitDisabled={isSubmitDisabled}
                />
              </Elements>
            </form>
          </div>
        </div>
        <FooterView />
      </div>
  );
};

export default SelectPackage;
