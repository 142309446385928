import React, { FC } from 'react';
import './ModalView.scss'
import ModalView from "./ModalView";
import Button from "@mui/material/Button";

interface Prop {
    onConfirm: any;
    onCancel: any;
    modalIsOpen: boolean;
    title?: string;
    modalText?: string;
}

const ConfirmationModal: FC<Prop> = ({
    onConfirm,
    onCancel,
    modalIsOpen = false,
    title = 'Confirmation',
    modalText,
}) => {

  return (
      <ModalView
          modalIsOpen={modalIsOpen}
          closeModal={onCancel}
          title={title}
      >
          <p className={"text"}>{modalText}</p>
          <div className={"actions"}>
              <div className={"OKButton"}>
                  <Button
                      onClick={onConfirm}
                      variant="contained"
                      className="stepContent__btn simpleBtn  -border"
                  >
                      Continue
                  </Button>
              </div>
              <div className={"cancelButton"}>
                  <Button
                      onClick={onCancel}
                      variant="contained"
                      className="stepContent__btn simpleBtn"
                  >
                      Cancel
                  </Button>
              </div>
          </div>
      </ModalView>
  );
};

export default ConfirmationModal;
