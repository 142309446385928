import React, {CSSProperties, FC, useEffect, useState} from 'react';
import {useAppDispatch, useAppSelector} from "../../../hooks/global";
import {fetchMyDfg, sendAffiliateRequest} from "../../../store/customer/customerSlice";
import Button from "@mui/material/Button";
import Tooltip from '@mui/material/Tooltip';
import Fade from '@mui/material/Fade';
import ClipLoader  from "react-spinners/ClipLoader";
import './Affiliates.scss'

interface Props {
    code: string;
    title: string;
    alias: string;
    image: any;
    odd?: boolean;
    children: any;
}

const override: CSSProperties = {
    display: "block",
    position: "absolute",
    margin: "0 auto",
    top: "5px",
};

const AffiliateCard: FC<Props> = ({code, alias, title, image, odd= false, children}) => {

    const dispatch = useAppDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const [copyTooltipOpened, setCopyTooltipOpened] = useState(false);
    const [sendTooltipOpened, setSendTooltipOpened] = useState(false);
    const {isAffiliateRequestLoading} = useAppSelector(state => state.customer);

    useEffect(() => {
        if (isAffiliateRequestLoading === false) {
            setIsLoading(false);
        }
    }, [isAffiliateRequestLoading]);

    const handleSendQuestion = (affiliate: string) => {
        setIsLoading(true);
        dispatch(sendAffiliateRequest({affiliate})).then(resp => {
            if (resp?.type === 'customer/send-affiliate-request/fulfilled') {
                setSendTooltipOpened(true);
                setTimeout(() => {
                    setSendTooltipOpened(false)
                }, 1500);
            }
        });
    }

    const handleCopy = () => {
        navigator.clipboard.writeText(code);
        setCopyTooltipOpened(true)
        setTimeout(() => {
            setCopyTooltipOpened(false)
        }, 1500);
    }

    return (
        <div className={`affiliatesCard ${odd ? 'odd' : ''}`}>
            <img className={'affiliatesCardImg'} src={image} alt={title}/>
            <div className={'affiliatesCardDesc'}>
                {children}
                {/*<div className={'affiliatesCardDescHeader'}>*/}
                {/*    Consulting Support*/}
                {/*</div>*/}
                {/*<div className={'affiliatesCardDescText'}>*/}
                {/*    {text}*/}
                {/*</div>*/}
                {/*<div className={'affiliatesCardDescCode'}>*/}
                {/*    You’re unique code <span>(click to copy)</span>*/}
                {/*</div>*/}
                <div className={'affiliatesCardDescBtns'}>
                    <Tooltip
                        PopperProps={{
                            disablePortal: true,
                        }}
                        open={copyTooltipOpened}
                        disableFocusListener
                        disableHoverListener
                        disableTouchListener
                        title="Copied"
                        TransitionComponent={Fade}
                        TransitionProps={{ timeout: 600 }}
                        placement="bottom"
                    >
                        <Button
                            onClick={handleCopy}
                            variant="contained"
                            className="affiliateBtn grey"
                        >
                            {code}
                        </Button>
                    </Tooltip>
                    <Tooltip
                        PopperProps={{
                            disablePortal: true,
                        }}
                        open={sendTooltipOpened}
                        disableFocusListener
                        disableHoverListener
                        disableTouchListener
                        title="Your request was sent"
                        TransitionComponent={Fade}
                        TransitionProps={{ timeout: 600 }}
                        placement="bottom"
                    >
                        <Button
                            onClick={()=>{handleSendQuestion(alias)}}
                            variant="contained"
                            className="affiliateBtn"
                            disabled={isLoading}
                        >
                            contact AFFILIATE
                            <ClipLoader
                                color="#2196fc"
                                loading={isLoading}
                                size={28}
                                cssOverride={override}
                            />
                        </Button>
                    </Tooltip>
                </div>
            </div>
        </div>
    );
};

export default AffiliateCard;
