import React, {FC, useState} from 'react';
import helpOpen from '../../../assets/images/help-open.png';
import helpClose from '../../../assets/images/help-close.png';
import Input from '@mui/material/Input';
import {useForm} from "react-hook-form";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import Button from "@mui/material/Button";
import {useAppDispatch, useAppSelector} from "../../../hooks/global";
import {sendQuestion} from "../../../store/customer/customerSlice";
import {FormHelperText} from "@mui/material";
import FadeLoader from "react-spinners/FadeLoader";
import './Help.scss';

const Help: FC = () => {
    const dispatch = useAppDispatch();
    const [helpState, setHelpState] = useState(false);
    const {isQuestionLoading} = useAppSelector(state => state.customer);
    const {
        register,
        handleSubmit,
        reset,
        formState: {errors},
    } = useForm({
        mode: 'all'
    });

    const handleHelpState = () => {
        reset();
        setHelpState(!helpState);
    }

    const handleSendQuestion = (data: any) => {
        dispatch(sendQuestion(data)).then(resp => {
            if (resp?.type === 'customer/send-question/fulfilled') {
                reset();
                setHelpState(false);
            }
        });
    }

    return (
        <div className={"help"}>
            {helpState && (
                <div className="helpForm">
                    <div className={'helpFormSpinner'}>
                        <FadeLoader
                            color={'#1e96fc'}
                            loading={isQuestionLoading}
                            cssOverride={{display: "block", margin: "0 auto"}}
                            aria-label="Loading Spinner"
                        />
                    </div>
                    <div className="helpFormHeader">Have Any Questions?</div>
                    <div className="helpFormSubHeader">We Would Like to Hear From You</div>
                    <div className="helpFormElementWrapper">
                        <Input
                            {...register('name', {
                                required: "This field is required",
                            })}
                            id={'name'}
                            name={'name'}
                            className={`helpFormInput ${errors.name ? 'error' : ''}`}
                            placeholder={'Your Name'}
                            error={!!errors.name}
                            inputProps={{maxLength: 100}}
                        />
                        {!!errors.name && (
                            <FormHelperText error >
                                {`${errors.name.message}`}
                            </FormHelperText>
                        )}
                    </div>
                    <div className="helpFormElementWrapper">
                        <Input
                            {...register('email', {
                                required: "This field is required",
                                pattern: {
                                    value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                    message: "Invalid email address"
                                }
                            })}
                            id={'email'}
                            name={'email'}
                            className={`helpFormInput ${errors.email ? 'error' : ''}`}
                            placeholder={'Your Email'}
                            inputProps={{maxLength: 150}}
                        />
                        {!!errors.email && (
                            <FormHelperText error >
                                {`${errors.email.message}`}
                            </FormHelperText>
                        )}
                    </div>
                    <div className="helpFormElementWrapper">
                        <TextareaAutosize
                            {...register('question', {
                                required: "This field is required",
                            })}
                            className={`helpFormTextarea ${errors.question ? 'error' : ''}`}
                            maxRows={4}
                            maxLength={2000}
                            placeholder={'Your Question'}
                        />
                        {!!errors.question && (
                            <FormHelperText error >
                                {`${errors.question.message}`}
                            </FormHelperText>
                        )}
                    </div>
                    <div className={'helpFormSubmit'}>
                        <Button
                            variant="contained"
                            className="drugFreeList__btn"
                            onClick={handleSubmit(handleSendQuestion)}
                            disabled={false}
                        >
                            SUBMIT
                        </Button>
                    </div>
                </div>
            )}
            <img className="helpIcon" src={helpState ? helpClose :helpOpen } onClick={handleHelpState} alt="Help"/>
        </div>
    );
};

export default Help;
