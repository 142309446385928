import React, {FC, useEffect, useState} from "react";
import {useStripe, useElements, CardNumberElement, CardCvcElement, CardExpiryElement} from "@stripe/react-stripe-js";
import {InputField} from "../../controls";
import FormControl from "@mui/material/FormControl";
import Button from "@mui/material/Button";
import {FormHelperText} from "@mui/material";
import Checkbox from '@mui/material/Checkbox';
import {ReactComponent as CheckboxIcon} from '../../../assets/images/icons/checkbox_ic.svg';
import {ReactComponent as CheckboxCheckedIcon} from '../../../assets/images/icons/checkbox-checked_ic.svg';
import FormControlLabel from '@mui/material/FormControlLabel';
import './StripeForm.scss'

const options = {
    style: {
        base: {
            fontSize: '16px',
            color: "#000",
            fontWeight: "400",
            letterSpacing: "0.025em",
            fontFamily: "Open Sans, sans-serif",
            "::placeholder": {
                color: "#aab7c4"
            }
        },
        invalid: {
            color: "#e0215d"
        }
    }
}

type Props = {
    handleGeneralSubmit: (data: any) => void,
    formTrigger: () => void,
    on3DConfirmPayment: (data: any) => void,
    clientSecret: string,
    isSubmitDisabled: boolean,
};

const SplitForm: FC<Props> = ({
                                  handleGeneralSubmit,
                                  formTrigger,
                                  on3DConfirmPayment,
                                  clientSecret,
                                  isSubmitDisabled = false
                              }) => {
    const stripe = useStripe();
    const elements = useElements();

    const [cardElementError, setCardElementError] = useState('');
    const [cardExpiryElementError, setCardExpiryElementError] = useState('');
    const [cardCvcElementError, setCardCvcElementError] = useState('');
    const [terms, setTerms] = useState(false);

    useEffect(() => {
        if (stripe && clientSecret) {
            stripe
                .handleCardAction(clientSecret)
                .then(result => {
                    if ('error' in result) {
                        on3DConfirmPayment({error: result.error});
                    } else {
                        on3DConfirmPayment({payment_intent_id: result.paymentIntent.id});
                    }
                });
        }
    }, [clientSecret]);

    const handleStripeSubmit = async (event: any) => {

        event.preventDefault();

        if (!stripe || !elements) return;

        const cardElement = elements.getElement(CardNumberElement);
        const cardExpiryElement = elements.getElement(CardExpiryElement);
        const cardCvcElement = elements.getElement(CardCvcElement);

        if (cardElement && cardExpiryElement && cardCvcElement) {

            cardElement.on('change', function (event) {
                setCardElementError(event?.error?.message || '');
                if (event?.error?.message) formTrigger();
            });

            cardExpiryElement.on('change', function (event) {
                setCardExpiryElementError(event?.error?.message || '');
                if (event?.error?.message) formTrigger();
            });

            cardCvcElement.on('change', function (event) {
                setCardCvcElementError(event?.error?.message || '');
                if (event?.error?.message) formTrigger();
            });

            const payload = await stripe.createPaymentMethod({type: "card", card: cardElement})
                .then(function (result) {
                    handleGeneralSubmit(result);
                });
        }
    };

    return (
        <>
            <div className="formContainer__row -grid-3 mb-0">
                <div className="formContainer__column">
                    <div className="formContainer__row -grid-1 mb-0">

                        <FormControl className="stripeInputField">
                            <div className="stripeInputField__title">Card Number</div>
                            <CardNumberElement
                                id={'CardNumberElement'}
                                className={`stripeInputField__input ${cardElementError ? 'error' : ''}`}
                                options={options}
                            />
                            {!!cardElementError && (
                                <FormHelperText error id={'cardElementError'}>
                                    {`${cardElementError}`}
                                </FormHelperText>
                            )}
                        </FormControl>

                    </div>
                </div>
                <div className="formContainer__column">
                    <div className="formContainer__row -grid-2 mb-0">

                        <FormControl className="stripeInputField">
                            <div className="stripeInputField__title">Expiration date</div>
                            <CardExpiryElement
                                id={'CardExpiryElement'}
                                className={`stripeInputField__input ${cardExpiryElementError ? 'error' : ''}`}
                                options={options}
                            />
                            {!!cardExpiryElementError && (
                                <FormHelperText error id={'cardElementError'}>
                                    {`${cardExpiryElementError}`}
                                </FormHelperText>
                            )}
                        </FormControl>

                        <FormControl className="stripeInputField">
                            <div className="stripeInputField__title">CVV</div>
                            <CardCvcElement
                                id={'CardCvcElement'}
                                className={`stripeInputField__input ${cardCvcElementError ? 'error' : ''}`}
                                options={options}
                            />
                            {!!cardCvcElementError && (
                                <FormHelperText error id={'cardElementError'}>
                                    {`${cardCvcElementError}`}
                                </FormHelperText>
                            )}
                        </FormControl>

                    </div>
                </div>
            </div>
                <FormControlLabel
                    className="authCheckbox terms"
                    control={
                        <Checkbox
                            onClick={()=>{setTerms(!terms)}}
                            icon={<CheckboxIcon/>}
                            checkedIcon={<CheckboxCheckedIcon/>}
                            defaultChecked={false}
                        />
                    }
                    label={<div className={'termsConditions'}>By clicking, you are confirming that you have read, understood and agree to the Drug Free Guru
                        <a href={'https://drugfreeguru.com/terms-conditions'} target='_blank' rel="noreferrer"> terms and conditions</a>.</div>}
                />
            <Button
                id={'submit'}
                disabled={!stripe || isSubmitDisabled || !terms}
                onClick={handleStripeSubmit}
                variant="contained"
                className="simpleBtn">
                Process My Order!
            </Button>
        </>
    );
};

export default SplitForm;
