import React, {FC} from 'react';
import { ROUTES } from '../../../router/routes';
import './NotFound.scss'

const NotFound: FC = () => {
    return (
        <div className="forgotPasswordContainer">
            <div className="forgotPasswordView">
                <form className="authForm">
                    <div className="text">
                        <h1 className={'notFound'}>404</h1>
                        <h2>Page Not Found</h2>
                        <div className="link">
                            <a href={ROUTES.SIGN_IN}>Go to Login Page</a>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default NotFound;
