import React, { FC } from 'react';
import './FooterView.scss'
import {Help} from "../index";

const FooterView: FC = () => {
  return (
      <div className="footerContainer">
        <p>Copyright © {(new Date().getFullYear())} Drug Free Guru</p>
        <div className="footerList">
            <ul>
                <li><a className={"footerListLink"} href={'https://drugfreeguru.com/privacy-policy'} target={'_blank'} rel="noreferrer">Privacy Policy</a></li>
                <li><a className={"footerListLink"} href={'https://drugfreeguru.com/terms-conditions'} target={'_blank'} rel="noreferrer">Terms & Conditions</a></li>
                <li><a className={"footerListLink"} href={'https://drugfreeguru.com/accessibility'} target={'_blank'} rel="noreferrer">Accessibility</a></li>
                <li><a className={"footerListLink"} href={'/sign-in'}>Log In</a></li>
            </ul>
        </div>
          <Help/>
      </div>
  );
};

export default FooterView;
