import React, { FC } from 'react';
import './InputField.scss'
import Input from '@mui/material/Input';
import FormControl from '@mui/material/FormControl';

interface Props {
  name?: string;
  title?: string;
  value?: string;
  placeholder?: string;
  onChange?: any;
  error?: any;
}

const InputField: FC<Props> = ({
    name,
    title,
    placeholder = '',
    onChange,
    value,
    error,

  }) => {

  return (
      <div>
        <FormControl className="inputField">
        {title && (
            <div className="inputField__title">{title}</div>
        )}
          <Input
              name={name}
              className={`inputField__input ${error ? 'error' : ''}`}
              placeholder={placeholder}
              onChange={onChange}
              value={value}
              error={error}
          />
        </FormControl>
      </div>
  );
};

export default InputField;
