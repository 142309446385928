import {configureStore} from '@reduxjs/toolkit';
import generalReducer from './general/generalSlice';
import authReducer from './auth/authSlice';
import customerReducer from './customer/customerSlice';
import questionnaireReducer from './questionnaire/questionnaireSlice';
import librarySlice from './library/librarySlice';

export const store = configureStore({
    reducer: {
        general: generalReducer,
        auth: authReducer,
        customer: customerReducer,
        questionnaire: questionnaireReducer,
        library: librarySlice,
    },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
