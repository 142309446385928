export enum ROUTES {
  HOME = '/',
  SIGN_IN = '/sign-in',
  FORGOT_PASSWORD = '/forgot-password',
  SET_PASSWORD = '/set-password',
  SELECT_PACKAGE = '/select-package',
  THX_PAGE = '/thanks',
  QUESTIONNAIRE = '/questionnaire',
  MY_DFG = '/my-dfg',
  AFFILIATES = '/affiliates',
  LIBRARY = '/library',
  LIBRARY_ROOT = '/library/:alias',
  LIBRARY_PAGE = '/page/:alias',
  NOT_FOUND_404 = '/404',
}
