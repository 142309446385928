import React, {FC, useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";
import Button from "@mui/material/Button";
import {useAppDispatch, useAppSelector} from "../../../../hooks/global";
import {fetchPages} from "../../../../store/library/librarySlice";
import Download from '../../../../assets/images/icons/download.svg';
import Eye from '../../../../assets/images/icons/eye.svg';
import '../Library.scss'

interface Props {
    categories: {
        title: string,
        alias: string
    }[],
    pages: {
        title: string,
        alias: string
    }[];
}

const Category: FC<Props> = ({categories, pages}) => {

    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const {category, isLoading} = useAppSelector(state => state.library);
    const [activeTab, setActiveTab] = useState<string>('');

    const handleTabClick = (tab: string) => {
        setActiveTab(tab);
    }

    useEffect(() => {
        if (categories && categories.length) {
            const firstCat = categories[0]?.alias || '';
            setActiveTab(firstCat);
            if (firstCat) {
                dispatch(fetchPages(firstCat));
            }
        }
    }, [categories]);

    useEffect(() => {
        if (activeTab) {
            dispatch(fetchPages(activeTab));
        }
    }, [activeTab]);

    return (
        <>
            <div className="pageContainer__view">
                <div className="pageHeader">
                    <div className="pageHeader__rowTitle">
                        <h2 className="pageHeader__title">{isLoading === false && category?.title}</h2>
                        <Button
                            onClick={() => navigate(-1)}
                            variant="contained"
                            className="btnEdit BackBtn"
                        >
                            Back
                        </Button>
                    </div>
                </div>

                {(categories && categories.length>0 && isLoading === false) && (
                    <ul className={'CategoryTabs'}>
                        {categories.map((item:any, key:number) => (
                            <li className={`CategoryTab ${item.alias === activeTab ? '-active' : ''}`}
                                key={key}
                                onClick={()=>handleTabClick(item.alias)}
                            >
                                {item.title}
                            </li>
                        ))}
                    </ul>
                )}

                <table className={'CategoryTable'}>
                    <thead>
                        <tr>
                            <td style={{width: "80%"}}>Document</td>
                            <td style={{width: "10%"}}>Download</td>
                            <td style={{width: "10%"}}>View</td>
                        </tr>
                    </thead>
                    <tbody>
                        {isLoading === false && pages && pages.length>0 && pages.map((item:any, key:number) => (
                            <tr key={key} className={`${key%2 ? '-even' : ''}`}>
                                <td>{item.title}</td>
                                <td>
                                    {(item?.type==='document' && item?.document && item?.document?.file) && (
                                        <a
                                            href={item.document.file}
                                            target={'_blank'}
                                            rel={"noreferrer"}
                                        >
                                            <img className="CategoryIcon" src={Download} alt="Download"/>
                                        </a>
                                    )}
                                </td>
                                <td>
                                    <a href={`/page/${item.alias}`}>
                                        <img className="CategoryIcon" src={Eye} alt="Eye"/>
                                    </a>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>

            </div>
        </>
    );
};

export default Category;
